import React, { useState} from 'react'

const RecruiterAssessment = ({recruitment, details, store}) => {
  const [viewType] = useState(true)
  const [edit, setEditData] = useState({})

  const capitalizeName = str => {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
  }

  return (
    <>
      {recruitment ? (
        <div className="form fv-plugins-bootstrap fv-plugins-framework">
          <h4 className="mt-0">Application Assessment</h4>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="assessment-date">
              Date
            </label>
            <input
              id="assessment-date"
              name="assess_date"
              type="date"
              className={`form-control`}
              onChange={e => setEditData({...edit, assess_date: e.target.value})}
              value={recruitment ? recruitment.assess_date : ''}
              readOnly={viewType | false}
              required
            />
          </div>

          <hr />

          <h4 className="mb-4">Exam result</h4>

          <p className="sub-header title">Test I: Intelligence Quotience Test</p>
          <div className="row">
            <div className="col">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label" htmlFor="intelligence-raw-score">
                  Raw Score
                </label>
                <input
                  id="intelligence-raw-score"
                  name="assess_raw_score"
                  type="number"
                  className={`form-control`}
                  onChange={e => setEditData({...edit, assess_raw_score: e.target.value})}
                  value={recruitment ? recruitment.assess_raw_score : ''}
                  readOnly={viewType | false}
                  required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group fv-plugins-icon-container">
                <label className="form-label" htmlFor="intelligence-percentage">
                  Percentage
                </label>
                <input
                  id="intelligence-percentage"
                  name="assess_percentage"
                  type="number"
                  className={`form-control`}
                  onChange={e => setEditData({...edit, assess_percentage: e.target.value})}
                  value={recruitment ? recruitment.assess_percentage : ''}
                  readOnly={viewType | false}
                  required
                />
              </div>
            </div>
          </div>

          <p className="sub-header title"> Test II: Occupational Test </p>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="occupational-test">
              Result
            </label>
            <select
              name="assess_occ_test"
              id="occupational-test"
              className={`form-control`}
              onChange={e => setEditData({...edit, assess_occ_test: e.target.value})}
              value={recruitment.assess_occ_test}
              disabled={viewType | false}
              required
            >
              <option value="">Rate</option>
              <option value="0">Passed</option>
              <option value="1">Failed</option>
            </select>
          </div>

          <p className="sub-header title"> Test III: Personality Test </p>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="personality-test">
              Result
            </label>
            <select
              name="assess_personality_test"
              id="personality-test"
              className={`form-control`}
              value={recruitment.assess_occ_test | ''}
              disabled={viewType | false}
              onChange={e => setEditData({...edit, assess_personality_test: e.target.value})}
              required
            >
              <option value="">- Rate -</option>
              <option value="0">Passed</option>
              <option value="1">Failed</option>
            </select>
          </div>

          <hr />

          <h4>Overall</h4>
          <label className="form-label mt-0" htmlFor="personality-test">
            Evaluation
          </label>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="overall-result">
              Result
            </label>
            <select
              id="overall-result"
              name="assess_evaluation"
              className={`form-control`}
              value={recruitment.assess_evaluation | ''}
              disabled={viewType | false}
              onChange={e => setEditData({...edit, assess_evaluation: e.target.value})}
              required
            >
              <option value="">- Rate -</option>
              <option value="1">Passed</option>
              <option value="2">Failed</option>
            </select>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="overall-remarks">
              Remarks
            </label>
            <textarea
              id="overall-remarks"
              name="asseess_remarks"
              placeholder="Remarks"
              className={`form-control`}
              onChange={e => setEditData({...edit, asseess_remarks: e.target.value})}
              value={recruitment ? recruitment.asseess_remarks : ''}
              readOnly={viewType | false}
              required
            />
          </div>

          <hr />
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label mt-0" htmlFor="occupational-test">
              Possible deployment store
            </label>
            <select
              id="occupational-test"
              name="assess_deployment_store"
              className={`form-control`}
              onChange={e => setEditData({...edit, assess_deployment_store: e.target.value})}
              value={recruitment.assess_deployment_store | ''}
              disabled={viewType | false}
              required
            >
              <option value="">Rate</option>
              {store.stores &&
                store.stores.map((v, k) => {
                  return (
                    <option key={k} value={v.id}>
                      {capitalizeName(v.store_name)}
                    </option>
                  )
                })}
            </select>
          </div>

          <hr />
          <h4> Appearance </h4>
          <div className="appearance">
            <div className="row ">
              <div className="col">
                <label className="form-label" htmlFor="pleasing-personality">
                  Pleasing Personality
                </label>
                <select
                  id="pleasing-personality"
                  name="assess_pleasing"
                  className={`form-control`}
                  value={recruitment.assess_pleasing}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_pleasing: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="groom-clothing">
                  Groom & Clothing
                </label>
                <select
                  id="occupational-test"
                  name="assess_groom"
                  className={`form-control`}
                  value={recruitment.assess_groom | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_groom: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="body-language">
                  Body Language
                </label>
                <select
                  id="body-language"
                  name="assess_body"
                  className={`form-control`}
                  value={recruitment.assess_body | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_body: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="eye-contact">
                  Eye Contact
                </label>
                <select
                  id="eye-contact"
                  name="assess_eye"
                  className={`form-control`}
                  value={recruitment.assess_eye | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_eye: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </div>
          <h4> Characteristics </h4>

          <div className="characteristics">
            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="assertive">
                  Assertive
                </label>
                <select
                  id="assertive"
                  name="assess_assertive"
                  value={recruitment.assess_assertive | ''}
                  disabled={viewType | false}
                  className={`form-control`}
                  onChange={e => setEditData({...edit, assess_assertive: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="natural-leader">
                  Natural leader
                </label>
                <select
                  id="natural-leader"
                  name="assess_natural"
                  className={`form-control`}
                  value={recruitment.assess_natural | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_natural: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="cooperative">
                  Cooperative
                </label>
                <select
                  id="cooperative"
                  name="assess_cooperative"
                  className={`form-control`}
                  value={recruitment.assess_cooperative | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_cooperative: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="natural-leader">
                  Responsible
                </label>
                <select
                  id="responsible"
                  name="assess_responsible"
                  className={`form-control`}
                  value={recruitment.assess_responsible | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_responsible: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="self-motivated">
                  Self Motivated
                </label>
                <select
                  id="self-motivated"
                  name="assess_self_motivated"
                  className={`form-control`}
                  value={recruitment.assess_self_motivated | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_self_motivated: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="patience">
                  Patience
                </label>
                <select
                  id="patience"
                  name="assess_patience"
                  className={`form-control`}
                  value={recruitment.assess_patience | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_patience: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="dedicated">
                  Dedicated
                </label>
                <select
                  id="dedicated"
                  name="assess_dedicated"
                  className={`form-control`}
                  value={recruitment.assess_dedicated | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_dedicated: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="success-oriented">
                  Success Oriented
                </label>
                <select
                  id="success-oriented"
                  name="assess_success_oriented"
                  className={`form-control`}
                  value={recruitment.assess_success_oriented | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_success_oriented: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="quick-learner">
                  Quick Learner
                </label>
                <select
                  id="quick-learner"
                  name="assess_quick_learner"
                  className={`form-control`}
                  value={recruitment.assess_quick_learner | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_quick_learner: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="patience">
                  Hardworking
                </label>
                <select
                  id="hardworking"
                  name="assess_hard_working"
                  className={`form-control`}
                  value={recruitment.assess_hard_working | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_hard_working: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="form-label" htmlFor="intelligent">
                  Intelligent
                </label>
                <select
                  id="intelligent"
                  name="assess_intelligent"
                  className={`form-control`}
                  value={recruitment.assess_intelligent | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_intelligent: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label" htmlFor="self-confident">
                  Self Confident
                </label>
                <select
                  id="self-confident"
                  name="assess_self_confident"
                  className={`form-control`}
                  value={recruitment.assess_self_confident | ''}
                  disabled={viewType | false}
                  onChange={e => setEditData({...edit, assess_self_confident: e.target.value})}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
          </div>
          <hr />

          <h4>Qualifications</h4>

          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="education">
                Education/ Training
              </label>
              <select
                id="education"
                name="assess_educ_training"
                className={`form-control`}
                value={recruitment.assess_educ_training | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_educ_training: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col">
              <label className="form-label" htmlFor="sales-experience">
                Sales Experience
              </label>
              <select
                id="sales-experience"
                name="assess_sales_exp"
                className={`form-control`}
                value={recruitment.assess_sales_exp | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_sales_exp: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="service-crew-experience">
                Service Crew Experience
              </label>
              <select
                id="service-crew-experience"
                name="assess_qualification_serv_crew_exp"
                className={`form-control`}
                value={recruitment.assess_qualification_serv_crew_exp | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_qualification_serv_crew_exp: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col">
              <label className="form-label" htmlFor="cashier-experience">
                Cashier Experience
              </label>
              <select
                id="cashier-experience"
                name="assess_cash_exp"
                className={`form-control`}
                value={recruitment.assess_cash_exp | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_cash_exp: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="communication-skills">
                Communication Skills
              </label>
              <select
                id="communication-skills"
                name="assess_comm_skill"
                className={`form-control`}
                value={recruitment.assess_comm_skill | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_comm_skill: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <hr />

          <h4>Decision/Problem Solving</h4>
          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="creativity">
                Creativity
              </label>
              <select
                id="creativity"
                name="assess_creativity"
                className={`form-control`}
                value={recruitment.assess_creativity | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_creativity: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col">
              <label className="form-label" htmlFor="logic">
                Logic
              </label>
              <select
                id="logic"
                name="assess_logic"
                className={`form-control`}
                value={recruitment.assess_logic | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_logic: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <div className="row w-50">
            <div className="col">
              <label className="form-label" htmlFor="service-crew-experience">
                Service Crew Experience
              </label>
              <select
                id="service-crew-experience"
                name="assess_decision_serv_crew_exp"
                className={`form-control`}
                value={recruitment.assess_decision_serv_crew_exp | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_decision_serv_crew_exp: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <hr />
          <h4> Reason for Selecting Particular Organization </h4>

          <div className="row">
            <div className="col">
              <label className="form-label" htmlFor="commitment">
                Commitment
              </label>
              <select
                id="commitment"
                name="assess_commitment"
                className={`form-control`}
                value={recruitment.assess_commitment | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_commitment: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col">
              <label className="form-label" htmlFor="knowledge-organization">
                Knowledge of Organization
              </label>
              <select
                id="knowledge-organization"
                name="assess_know_org"
                className={`form-control`}
                value={recruitment.assess_know_org | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_know_org: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="knowledge-industry">
                Knowledge of Industry
              </label>
              <select
                id="knowledge-industry"
                name="assess_know_ind"
                className={`form-control`}
                value={recruitment.assess_know_ind | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_know_ind: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <hr />
          <h4>Job Expectation</h4>
          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="realistic">
                Realistic
              </label>
              <select
                id="realistic"
                name="assess_realistic"
                className={`form-control`}
                value={recruitment.assess_realistic | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_realistic: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="match-employer-needs">
                Match Employer's Needs
              </label>
              <select
                id="match-employer-needs"
                name="assess_match_needs"
                className={`form-control`}
                value={recruitment.assess_match_needs | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_match_needs: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <hr />
          <h4>Weakness</h4>
          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="nervous-around-people">
                Getting Nervous around people
              </label>
              <select
                id="nervous-around-people"
                name="assess_nervous"
                className={`form-control`}
                value={recruitment.assess_nervous | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_nervous: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="debater">
                Debater
              </label>
              <select
                id="debater"
                name="assess_debater"
                className={`form-control`}
                value={recruitment.assess_debater | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_debater: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="going-out-of-ones-way">
                Going out of one's way
              </label>
              <select
                id="going-out-of-ones-way"
                name="assess_out_of_way"
                className={`form-control`}
                value={recruitment.assess_out_of_way | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_out_of_way: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="sensitive-to-criticisms">
                Sensitive to Criticisms
              </label>
              <select
                id="sensitive-to-criticisms"
                name="assess_sensitive_criticism"
                className={`form-control`}
                value={recruitment.assess_sensitive_criticism | ''}
                disabled={viewType | false}
                onChange={e => setEditData({...edit, assess_sensitive_criticism: e.target.value})}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <hr />
          <h4> Background Investigation </h4>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="background-check-date">
              Date of Background check
            </label>
            <input
              id="background-check-date"
              name="assess_bi_date"
              type="date"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_date : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_date: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="position-held">
              Position held
            </label>
            <input
              id="position-held"
              name="positionHeld"
              type="text"
              value={recruitment ? recruitment.assess_bi_pos_held : ''}
              readOnly={viewType | false}
              className={`form-control`}
              onChange={e => setEditData({...edit, assess_bi_pos_held: e.target.value})}
              required
            />
          </div>

          <hr className="mb-0" />

          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="company-name">
                Company Name
              </label>
              <input
                id="company-name"
                name="assess_bi_company"
                type="text"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_company : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_company: e.target.value})}
                required
              />
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="company-name">
                Name of Contact Person
              </label>
              <input
                id="company-name"
                name="assess_bi_contact_pers"
                type="text"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_contact_pers : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_contact_pers: e.target.value})}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="position">
                Position
              </label>
              <input
                id="position"
                name="assess_bi_contact_pers_pos"
                type="text"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_contact_pers_pos : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_contact_pers_pos: e.target.value})}
                required
              />
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="contact-number">
                Contact Number
              </label>
              <input
                id="contact-number"
                name="assess_bi_contact_pers_no"
                type="text"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_contact_pers_no : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_contact_pers_no: e.target.value})}
                required
              />
            </div>
          </div>

          <hr />
          <h4>Date of Employment</h4>
          <div className="row">
            <div className="col-6">
              <label className="form-label" htmlFor="start-date">
                Start Date
              </label>
              <input
                id="start-date"
                name="assess_bi_appnt_startD"
                type="date"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_appnt_startD : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_appnt_startD: e.target.value})}
                required
              />
            </div>
            <div className="col-6">
              <label className="form-label" htmlFor="end-date">
                Until
              </label>
              <input
                id="end-date"
                name="assess_bi_appnt_to"
                type="date"
                className={`form-control`}
                value={recruitment ? recruitment.assess_bi_appnt_to : ''}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_appnt_to: e.target.value})}
                required
              />
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="going-out-of-ones-way">
              Performance
            </label>
            <select
              id="going-out-of-ones-way"
              name="assess_bi_perfm"
              className={`form-control`}
              value={recruitment.assess_bi_perfm | ''}
              disabled={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_perfm: e.target.value})}
              required
            >
              <option value="" disabled="">
                - Rate -
              </option>
              <option value="1">Fair</option>
              <option value="2">Average</option>
              <option value="3">Above Averarge</option>
              <option value="4">Excellent</option>
            </select>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="relationship-with-peers">
              Relationship with peers
            </label>
            <input
              id="relationship-with-peers"
              name="assess_bi_rel_peers"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_rel_peers : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_rel_peers: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="relationship-with-superiors">
              Relationship with superiors
            </label>
            <input
              id="relationship-with-superiors"
              name="assess_bi_rel_superiors"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_rel_superiors : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_rel_superiors: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="violations">
              Violations/ Derogatory record
            </label>
            <input
              id="violations-derogatory-record"
              name="assess_bi_violations_derogatory"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_violations_derogatory : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_violations_derogatory: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="reason-for-leaving">
              Reason for Leaving
            </label>
            <textarea
              id="reason-for-leaving"
              name="assess_bi_rol"
              placeholder="Reason for leaving"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_rol : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_rol: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="additional-training">
              Additional training
            </label>
            <input
              id="additional-training"
              name="assess_bi_add_training"
              className={`form-control`}
              value={recruitment ? recruitment.assess_bi_add_training : ''}
              readOnly={viewType | false}
              onChange={e => setEditData({...edit, assess_bi_add_training: e.target.value})}
              required
            />
          </div>

          <div className="form-group fv-plugins-icon-container">
            <div className="form-check">
              <input
                type="checkbox"
                id="formHorizontalCheck"
                name="assess_bi_recommended"
                className="form-check-input"
                checked={recruitment.assess_bi_recommended === 'on' ? true : false}
                readOnly={viewType | false}
                onChange={e => setEditData({...edit, assess_bi_recommended: e.target.value})}
                required
              />
              <label title="" htmlFor="formHorizontalCheck" className="form-check-label">
                Recommended
              </label>
            </div>
          </div>
        </div>
      ) : (
        'No Recruitment Review yet.'
      )}
    </>
  )
}

export default RecruiterAssessment

import PropTypes from 'prop-types'
import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/_helpers'

const NewApplicantListItem = ({image, applicantName, dateCreated, company, id, action, status}) => {
  const style = {
    fontSize: 8
  }
  return (
    <div className="table-card">
      <div className="table-card_name">
        <div className="symbol symbol-50 symbol-light mr-4">
          <span className="symbol-label">
            <span className="svg-icon align-self-end">
              <img src={toAbsoluteUrl(`${image}`)} alt={applicantName} />
            </span>
          </span>
        </div>
        <div className="table__name d-block">
          <p className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{applicantName}</p>
          <p className="text-muted font-weight-bold">{dateCreated}</p>
        </div>
      </div>

      <div className="px-0 text-right table-action">
        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => action(company, id)}>
          View
        </button>
        {status == 0 
          ? 
            <span className="mt-5 badge badge-primary w-100 h-100">Examination</span>
          : 
            status == 1 
          ?
            <span className="mt-5 badge badge-dark w-100 h-100">In-Review</span>
          : 
            status == 2
          ?
            <span className="mt-5 badge badge-success w-100 h-100">Head Interview</span>  
          : 
            status == 3
          ?
            <span className="mt-5 badge badge-danger w-100 h-100">Store Interview</span>
          :   
            status == 4
          ?
            <span className="mt-5 badge badge-warning w-100 h-100" style={style}>Requirements Submission</span>
          : 
            <span className="mt-5 badge badge-info w-100 h-100" style={style}>Completed Requirements</span> 
        }
       
      </div>
    </div>
  )
}

NewApplicantListItem.defaultProps = {
  image:
    'https://images.pexels.com/photos/4373060/pexels-photo-4373060.jpeg?cs=srgb&dl=pexels-susn-dybvik-4373060.jpg&fm=jpg',
  applicantName: 'Test User',
  dateCreated: 'February 25 2021 03:29 PM',
  company: 'APEX',
  id: 1,
}

NewApplicantListItem.propTypes = {
  image: PropTypes.string,
  applicantName: PropTypes.string,
  dateCreated: PropTypes.string,
  company: PropTypes.string,
  id: PropTypes.string,
}

export default NewApplicantListItem

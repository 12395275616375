import {Button} from '@material-ui/core'
import React from 'react'
//import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap'

// import {
//     makeStyles,

// } from "@material-ui/core/styles";

// const useStyles1 = makeStyles(theme => ({
//     button: {
//         margin: theme.spacing(1)
//     },
//     input: {
//         display: "none"
//     }
// }));

// const useStyles2 = makeStyles(theme => ({
//     container: {
//         display: "flex",
//         flexWrap: "wrap"
//     },
//     textField: {
//         marginLeft: theme.spacing(1),
//         marginRight: theme.spacing(1)
//     },
//     dense: {
//         marginTop: theme.spacing(2)
//     },
//     menu: {
//         width: 200
//     }
// }));

const ComingSoonModal = ({show, onClose}) => {
  // const classes1 = useStyles1();
  // const classes2 = useStyles2();

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Coming Soon</Modal.Title>
      </Modal.Header>

      <Modal.Body></Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

ComingSoonModal.propTypes = {}

export default ComingSoonModal

import {Button, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {Alert, Modal} from 'react-bootstrap'
import {Notice} from '../../../_metronic/_partials/controls'

import {getUserByToken} from 'api/public/auth'
import {postStoreAccounts} from 'api/private/store'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
const useStyles1 = makeStyles(theme => ({
  button: {},
  input: {
    display: 'none',
  },
}))

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const AddNewStoreForm = ({show, onClose}) => {
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const [data, setData] = useState([])
  const [process, setProcess] = useState({
    success: null,
    loading: false,
  })
  const [user, setUser] = useState([])

  const getUser = async () => {
    const r = await getUserByToken()
    if (r.status === 200) {
      setUser({...user, ...r.data})
      setData({...data, company: ls.get('company'), created_by: user.id, type: 5, store_address_url: ''})
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await setProcess({
      loading: true,
    })
    const r = await postStoreAccounts(data)
    if (r) {
      await setProcess({
        loading: false,
        success: true,
      })
      await setData([])
    } else {
      await setProcess({
        loading: false,
      })
    }

    setTimeout(onClose, 700)
  }

  useEffect(() => {
    getUser()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const [store_type] = useState(
    [
      'Jollibee', 
      'Mang Inasal', 
      'Zarks', 
      'Chowking', 
      'Greenwich', 
      'Red Ribbon', 
      'EBC', 
      'Digido', 
      'Primegoods', 
      'SKZ', 
      'Newton', 
      'World of Fun', 
      'Funtime', 
      'Popeyes', 
      'Lans Eatery', 
      "Sarah's Bakehouse", 
      "iPS", 
      "Sky Cable", 
      "Chunky Boss", 
      "Khao Khao Thai", 
      "Chicken House", 
      "Gram Cafe and Pancakes", 
      "Tiger Sugar", 
      "Kuya J", 
      "Chi Cha San Chen", 
      "Gong Cha",
      "Army Navy",
      "New Trends",
      "Belgian Waffle",
      "Elevate Bistro",
      "SMDC",
      "BB.Q Chicken",
      "JJAP Jewelries",
      "CBTL",
      "NONO's",
      "Samgyupsalamat",
      "City Roast" 
      ]
    )

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add new store</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Notice icon="flaticon-warning font-primary">
            <span>Provide context of the store, Note the password will be sent to the provided email address.</span>{' '}
            <span>
              If you encountered a problem. Kindly file a
              <a
                target="_blank"
                className="font-weight-bold"
                rel="noopener noreferrer"
                href="https://flask.io/8g7bciS8AmUY"
              >
                support ticket
              </a>
            </span>
          </Notice>
          <div>
            {/*<TextField
              id="outlined-select-currency-native"
              select
              label="-"
              className={classes2.textField}
              defaultValue={''}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes2.menu,
                },
              }}
              helperText="Type of Store"
              margin="normal"
              variant="outlined"
              onInput={e => (e ? setData({...data, store_type: e.target.value}) : false)}
            >
              <option value="" disabled>
                Store Type
              </option>
              {store_type && store_type.length > 0
                ? store_type.map(d => {
                    return (
                      <option key={d} value={d}>
                        {d}
                      </option>
                    )
                  })
                : ''}
            </TextField>*/}
            <TextField
              id="outlined-name"
              label="Store branch"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, name: e.target.value}) : false)}
            />
            <TextField
              id="outlined-location"
              label="Location"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, store_address: e.target.value}) : false)}
            />
            <TextField
              id="outlined-email"
              label="Email address"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, email: e.target.value}) : false)}
            />

            <div className="mx-1">
              {process.success !== null ? (
                <Alert variant={process.success === true ? 'success' : 'danger'}>
                  {process.success === true ? (
                    <p className="mb-0">New store created!</p>
                  ) : (
                    <p className="mb-0">There seems to be a problem.</p>
                  )}
                </Alert>
              ) : (
                ''
              )}
            </div>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes1.button}
              disabled={process.loading}
            >
              Save Store
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

AddNewStoreForm.propTypes = {}

export default AddNewStoreForm

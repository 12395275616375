import React, {useState, useEffect} from 'react'
import {getPersonnelLogs} from 'api/private/documents'
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import {Button} from '@material-ui/core'

const {SearchBar} = Search
var dayjs = require('dayjs')
const PersonnelLogs = props => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState({
    logs: [],
    loading: false,
/*    selectedAccess: '',*/
    /*sortedData: [],*/
  })

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const personnelLogs = async () => {
    const r = await getPersonnelLogs()
    setData({...data, loading: true})
    if (r.length > 0) {
      let dt = []
      r.map(d =>
        dt.push({
          id: d.id,
          hr: capitalizeName(d.apex_personnel_name),
          action: d.action,
          date: d.date,
          hr_email: d.apex_personnel_email,
        }),
      )
      
      setData({...data, logs: dt, loading: false})
      
    }
  }

  useEffect(() => {
    personnelLogs()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'id',
      text: 'Process ID',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
    },
    {
      dataField: 'hr',
      text: 'Apex Personnel Name',
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
    },
    {
      dataField: 'hr_email',
      text: 'Apex Personnel Email',
      sort: true,
    },
  ]

  return (
   <div>
      <div>
        <ToolkitProvider
          keyField="id"
          data={data.logs}
          columns={columns}
          search
          defaultSorted={defaultSorted}
          pagination={PaginationFactory()}
        >
          {props => (
            <div>
              <SearchBar {...props.searchProps} />
              <hr />
              <Table
                defaultSorted={defaultSorted}
                className="w-100"
                pagination={PaginationFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

PersonnelLogs.propTypes = {}

export {PersonnelLogs}

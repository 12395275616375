import React from 'react'
import 'style/_review.scss'
import HistorySurgery from './surgery'
import HistorySelf from './self'
import HistoryFamily from './family'

const ResumeMedical = ({dt}) => {
  return (
    <div className="form-group">
      <h2>Medical Examinations</h2>
      <hr />
      <HistorySurgery d={dt} />
      <HistorySelf d={dt} />
      <HistoryFamily d={dt} />
    </div>
  )
}
export default ResumeMedical

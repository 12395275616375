import React, {Suspense, lazy} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {LayoutSplashScreen, ContentRoute} from '../_metronic/layout'
import {BuilderPage} from './pages/BuilderPage'
import {MyPage} from './pages/MyPage'
import {DashboardPage} from './pages/DashboardPage'
import {SearchApplicantPage} from './pages/search-applicants/SearchApplicantPage'
import {NewApplicantsPage} from './pages/new-applicants/NewApplicantsPage'
import ApexAdminDashboardPageContainer from './pages/apex-admin-dashboard/ApexAdminDashboardPageContainer'
import {ApplicantLogsPage} from './pages/applicant-logs/ApplicantLogsPage'
import {EmailsPage} from './pages/emails/EmailsPage'
import {ExamLogsPage} from './pages/exam-logs/ExamLogsPage'
import {PeopleListPage} from './pages/people-list/PeopleListPage'
import {SettingsPage} from './pages/settings/SettingsPage'
import {StoreAccountsPage} from './pages/store-account-page/StoreAccountsPage'
import {StoreListPage} from './pages/store-list-page/StoreListPage'
import {StoreDetailsPage} from './pages/store-list-page/StoreDetailsPage'
import {ResumePoolPage} from './pages/resume-pool/ResumePoolPage'
import {StoreReviewPage} from './pages/store-review/StoreReviewPage'
import {RecruitmentHeadReviewPage} from './pages/recruitment-head-review/RecruitmentHeadReviewPage'
import {FailApplicantPage} from './pages/fail-applicants'
import JobsExamsPageContainer from './pages/jobs-exams/JobsExamsPageContainer'
import {DetailsJobsExams} from './pages/jobs-exams/DetailsJobsExams'
import {ReviewPendingDocumentsPage} from './pages/review-documents/ReviewPendingDocumentsPage'
import {ReviewCompleteDocumentsPage} from './pages/review-documents/ReviewCompleteDocumentsPage'
import {ReviewTrainingPage} from './pages/review-documents/ReviewTrainingPage'
import {RecruitmentPage} from './pages/recruitment/RecruitmentPage'
import {HumanRelationsPage} from './pages/human-relations/HumanRelations'

import {ReviewApplicantPage} from './pages/review-applicant/ReviewApplicantPage'
import {ViewApplicantPage} from './pages/view-applicant/ViewApplicantPage'
import {ViewPersonnelPage} from './pages/view-applicant/ViewPersonnelPage'
import {PersonnelLogs} from './pages/personnel-logs/PersonnelLogs'
import UserProfilePage from './modules/UserProfile/UserProfilePage'


const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'))

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /applicants/new for now rather than /board as it is for coming soon. */
          <Redirect exact from="/" to="/applicants/new" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />

        <ContentRoute path="/board" component={RecruitmentPage} />

        <ContentRoute path="/applicants/new" component={NewApplicantsPage} />
        <ContentRoute path="/applicants/search" component={SearchApplicantPage} />
        <ContentRoute path="/applicants/review" component={RecruitmentHeadReviewPage} />
        <ContentRoute path="/applicants/store/review" component={StoreReviewPage} />
        <ContentRoute path="/applicants/fail" component={FailApplicantPage} />
        <ContentRoute path="/applicants/pool" component={ResumePoolPage} />

        <ContentRoute path="/reports" component={ApexAdminDashboardPageContainer} />

        <ContentRoute path="/stores" component={StoreListPage} />
        <ContentRoute path="/store/details/:id/:company" component={StoreDetailsPage} />
        <ContentRoute path="/store-accounts" component={StoreAccountsPage} />

        <ContentRoute path="/people" component={PeopleListPage} />

        <ContentRoute path="/documents/pending" component={ReviewPendingDocumentsPage} />
        <ContentRoute path="/documents/complete" component={ReviewCompleteDocumentsPage} />
        <ContentRoute path="/documents/training" component={ReviewTrainingPage} />

        <ContentRoute path="/applicant/review/:id/:company" component={ReviewApplicantPage} />
        <ContentRoute path="/applicant/view/:id/:company" component={ViewApplicantPage} />
        <ContentRoute path="/personnel/view/:id/:company" component={ViewPersonnelPage} />

        <ContentRoute path="/system/email" component={EmailsPage} />
        <ContentRoute path="/system/exams" component={ExamLogsPage} />
        <ContentRoute path="/system/applicants" component={ApplicantLogsPage} />

        <ContentRoute path="/system/setting" component={SettingsPage} />
        <ContentRoute path="/system/settings/jobs" component={JobsExamsPageContainer} />
        <ContentRoute path="/system/jobs-exam/:id/:company" component={DetailsJobsExams} />

        <ContentRoute path="/e-commerce" component={ECommercePage} />

        <ContentRoute path="/user-profile" component={UserProfilePage} />
        <ContentRoute path="/human-relations" component={HumanRelationsPage} />
        <ContentRoute path="/system/personnel-logs" component={PersonnelLogs} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}

import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {
  generateUploadUrl,
  postUploadDocument,
  postDocumentChange,
  postUpdateDocumentPending,
  getSanctionsList,
  transferTraining
} from 'api/private/documents'
import {getApplicantStoreDeploy} from 'api/private/store'
import {updateApplicantProfile, updateApplicationPhone, updateApplicationGov} from 'api/private/applicants'
import {postHrPersonnel} from 'api/private/recruitment'
import 'style/_table.scss'
import SecureLS from 'secure-ls'
import {getStores} from 'api/private/store'

const ls = new SecureLS({encodingType: 'aes'})

const PreviewCard = ({previewData, handleUpdateStatus, loadingPending, loadingComplete}) => {


  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

useEffect(() =>{
    console.log(previewData)
},[])
  
  return (
    <div className="form preview-card">
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className="mt-5 text-center">
          <div className="symbol symbol-60 symbol-xxl-100 mr-5">
            {/* <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${profile_img})`,
              }}
            ></div> */}
          </div>
          <div>
            <span href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
              {capitalizeName(previewData.applicantName.toLowerCase())}
            </span>  
          </div>
        </div>
      </div>
      <table className="row">
        <tbody className="form-group row">
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Reference Number:</td>
            <td className="font-weight-bold">{previewData.id}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Email:</td>
            <td className="font-weight-bold">{previewData.email}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>
              Contact Number: 
            </td> 
            <td className="font-weight-bold">{previewData.mobileNumber}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>
              Date Applied: 
            </td> 
            <td className="font-weight-bold">{new Date(previewData.dateApplied).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
      <div className="px-0 text-center table-action">
        <button className="btn btn-light-danger font-weight-bolder font-size-sm mr-3"
        onClick={() => handleUpdateStatus(previewData.id, 4, 'pending')}
        disabled={loadingPending}>
          {loadingPending ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '5px' }}
            ></span>
            Updating...
          </>
        ) : (
          'Pending'
        )}
        </button>
        <button className="btn btn-light-success font-weight-bolder font-size-sm"
         onClick={() => handleUpdateStatus(previewData.id, 5, 'complete')}
         disabled={loadingComplete}>
          {loadingComplete ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '5px' }}
            ></span>
            Updating...
          </>
        ) : (
          'Complete'
        )}
        </button>
      </div>
    </div>
  )
}

export default PreviewCard

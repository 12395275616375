import React from 'react'
import 'style/_review.scss'

const ResumeFamily = ({dt}) => {
  return (
    <div className="form-group">
      <h2>Family Background</h2>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <table>
            <tbody>
              <tr>
                <td width="200">Father Name:</td>
                <td className="font-weight-bold text-uppercase">{dt.father}</td>
              </tr>
              <tr>
                <td width="200">Birth Date:</td>
                <td className="font-weight-bold text-uppercase">{dt.father_bdate}</td>
              </tr>
              <tr>
                <td width="200">Age:</td>
                <td className="font-weight-bold text-uppercase"> {dt.father_age}</td>
              </tr>
              <tr>
                <td width="200">Occupation:</td>
                <td className="font-weight-bold text-uppercase"> {dt.father_occ}</td>
              </tr>

              <tr>
                <td width="200">Mother Maiden Name:</td>
                <td className="font-weight-bold text-uppercase">{dt.mother}</td>
              </tr>
              <tr>
                <td width="200">Birth Date:</td>
                <td className="font-weight-bold">{dt.mother_bdate}</td>
              </tr>
              <tr>
                <td width="200">Age:</td>
                <td className="font-weight-bold">{dt.mother_age}</td>
              </tr>
              <tr>
                <td width="200">Occupation:</td>
                <td className="font-weight-bold">{dt.mother_occ}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <table>
            <tbody>
              <tr>
                <td width="200">Spouse Name:</td>
                <td className="font-weight-bold text-uppercase">{dt.spouse}</td>
              </tr>

              <tr>
                <td width="200">Age:</td>
                <td className="font-weight-bold">{dt.spouse_age}</td>
              </tr>

              <tr>
                <td width="200">Occupation:</td>
                <td className="font-weight-bold">{dt.spouse_occ}</td>
              </tr>
              <tr>
                <td width="200">Name of Child/Children:</td>
                <td className="font-weight-bold">{dt.spouse_child}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ResumeFamily

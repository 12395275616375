import React, {useEffect, useState} from 'react'
import 'style/_table.scss'
import ResumeHeader from './header'
import ResumePersonal from './personal'
import ResumeEducation from './education'
import ResumeFamily from './family'
import ResumeWork from './workExp'
import ResumeTraining from './training'
import ResumeMedical from './medical'
import ResumeOther from './other'

export default function Resume({details}) {
  const [data, setData] = useState({
    header: [],
    personal: [],
    education: [],
    family: [],
    work: [],
    training: [],
    medical: [],
    other: [],
  })

  const checkOriginApplication = hiring => {
    switch (hiring) {
      case 'A':
        return 'Employee Referral'
      case 'B':
        return 'Government Ads (PESO/Barangay)'
      case 'C':
        return 'Job Portal (Indeed, Trabahanap, Jobstreet, Bestjobs, LinkedIn or etc.'
      case 'D':
        return 'Social Media'
      case 'E':
        return 'Walk-In'
      default:
        return ''
    }
  }
  const formatData = () => {
    const d = details.length > 0 ? details[0] : []
    const headerFr = {
      img: d.profile_img,
      email: d.email,
      number: d.contact,
      pres_addr: d.pres_addr,
      perm_addr: d.perm_addr,
      name: d.name,
    }

    const personalFr = {
      sex: d.sex,
      civil_status: d.civil_status,
      religion: d.religion,
      citizenship: d.nationality,
      height: d.height,
      weight: d.weight,
      blood_type: d.blood_type,
      tin: d.tin,
      phil_health: d.phl,
      sss: d.sss,
      pagibig: d.pagibig,
      emerg_name: d.emerg_name,
      emerg_contact: d.emerg_contact,
      emerg_relationship: d.emerg_relationship,
    }

    const educationFr = {
      elem: d.educ_elem,
      elem_awards: d.educ_elem_mentions,
      elem_completed: d.educ_elem_year,
      hs: d.educ_hs,
      hs_awards: d.educ_hs_mentions,
      hs_completed: d.educ_hs_year,
      college: d.educ_college,
      college_course: d.educ_college_course,
      college_awards: d.educ_cl_mentions,
      college_isgrad: d.educ_college_graduated,
      college_completed: d.educ_college_year,
      vocation_isChecked: d.vocational_chkbox,
      vocation: d.educ_voc,
      vocation_course: d.educ_voc_course,
      vocation_completed: d.educ_voc_year,
    }

    const familyFr = {
      father: d.person_father,
      father_age: d.person_father_age,
      father_bdate: d.person_father_bdate,
      father_occ: d.person_father_occ,
      mother: d.person_mother,
      mother_age: d.person_mother_age,
      mother_bdate: d.person_mother_bdate,
      mother_occ: d.person_mother_occ,
      spouse: d.person_spouse_name,
      spouse_age: d.person_spouse_age,
      spouse_occ: d.person_spouse_occ,
      spouse_child: d.person_spouse_child,
    }

    const workFr = {
      latest: d.person_position,
      latest_start: d.person_startDate,
      latest_end: d.person_lastDate,
      latest_pow: d.person_place_work,
      latest_salary: d.person_month_salary,
      latest_reason: d.person_reason_for_leaving,
      reference: d.person_contact_name,
      reference_contact: d.person_contact_no,
      reference_pos: d.person_contact_position,
      reference_address: d.person_contact_address,
    }

    const trainingFr = {
      training: d.person_training_course,
      training_dt: d.person_training_details,
      training_sponsor: d.person_sponsor,
      training_convicted: d.person_convicted,
      skill: d.person_special_skill,
      recognition: d.person_recognition,
      medical_mem: d.person_med_membership,
    }
    const medicalFr = {
      illness_measles: d.illness_measles,
      illness_mumps: d.illness_mumps,
      illness_chickenpox: d.illness_chickenpox,
      illness_rheumatic: d.illness_rheumatic,
      illness_none: d.illness_none,
      surgery: d.surgery_name,
      surgery_hospital: d.surgery_hospital,
      surgery_yr: d.surgery_year,
      other_hospital: d.other_hosp_name,
      other_hosp_hospital: d.other_hosp_hospital,
      other_hosp_year: d.other_hosp_year,
      medHISTORY_alcohol_abuse: d.medHISTORY_alcohol_abuse,
      medHISTORY_anemia: d.medHISTORY_anemia,
      medHISTORY_anesthetic: d.medHISTORY_anesthetic,
      medHISTORY_anxiety: d.medHISTORY_anxiety,
      medHISTORY_asthma: d.medHISTORY_asthma,
      medHISTORY_autoimmuneProblems: d.medHISTORY_autoimmuneProblems,
      medHISTORY_birthDefetchs: d.medHISTORY_birthDefetchs,
      medHISTORY_bladdeProblems: d.medHISTORY_bladdeProblems,
      medHISTORY_bleedingDisease: d.medHISTORY_bleedingDisease,
      medHISTORY_bloodClots: d.medHISTORY_bloodClots,
      medHISTORY_bloodTransfusion: d.medHISTORY_bloodTransfusion,
      medHISTORY_bowelDisease: d.medHISTORY_bowelDisease,
      medHISTORY_depresion: d.medHISTORY_depresion,
      medHISTORY_diabetes: d.medHISTORY_diabetes,
      medHISTORY_hearing_impairment: d.medHISTORY_hearing_impairment,
      medHISTORY_heartAttack: d.medHISTORY_heartAttack,
      medHISTORY_heartPain: d.medHISTORY_heartPain,
      medHISTORY_hepatitisA: d.medHISTORY_hepatitisA,
      medHISTORY_hepatitisB: d.medHISTORY_hepatitisB,
      medHISTORY_hepatitisC: d.medHISTORY_hepatitisC,
      fam_HISTORY_adopted: d.fam_HISTORY_adopted,
      fam_HISTORY_alcohol_abuse: d.fam_HISTORY_alcohol_abuse,
      fam_HISTORY_anemia: d.fam_HISTORY_anemia,
      fam_HISTORY_anesthetic: d.fam_HISTORY_anesthetic,
      fam_HISTORY_anxiety: d.fam_HISTORY_anxiety,
      fam_HISTORY_athritis: d.fam_HISTORY_athritis,
      fam_HISTORY_bladdeProblems: d.fam_HISTORY_bladdeProblems,
      fam_HISTORY_bleedingDisease: d.fam_HISTORY_bleedingDisease,
      fam_HISTORY_diabetes: d.fam_HISTORY_diabetes,
      fam_HISTORY_heartDisease: d.fam_HISTORY_heartDisease,
      fam_HISTORY_cholesterol: d.fam_HISTORY_cholesterol,
      fam_HISTORY_kidneyDisease: d.fam_HISTORY_kidneyDisease,
      fam_HISTORY_leukemia: d.fam_HISTORY_leukemia,
      fam_HISTORY_respiratoryDisease: d.fam_HISTORY_respiratoryDisease,
      fam_HISTORY_migraine: d.fam_HISTORY_migraine,
      fam_HISTORY_osteoporosis: d.fam_HISTORY_osteoporosis,
      fam_HISTORY_seizures: d.fam_HISTORY_seizures,
      fam_HISTORY_severAllergy: d.fam_HISTORY_severAllergy,
      fam_HISTORY_stroke: d.fam_HISTORY_stroke,
      famHISTORY_thyroid: d.famHISTORY_thyroid,
      family_agree_no_history: d.family_agree_no_history,
      family_agree_no_hospitalization: d.family_agree_no_hospitalization,
      medical_agree_no_history: d.medical_agree_no_history,
      medical_agree_no_hospitalization: d.medical_agree_no_hospitalization,
      menstrual_cycle_length: d.menstrual_cycle_length,
      menstrual_first_day: d.menstrual_first_day,
      menstrual_last_day: d.menstrual_last_day,
      menstrual_period_length: d.menstrual_period_length,
      menstrual_regular: d.menstrual_regular,
      menstrual_experience_pain: d.menstrual_experience_pain,
      last_menstruation_date: d.last_menstruation_date,
      menstrual_cycle: d.menstrual_cycle,
      medical_history_dysmenorrhea: d.medical_history_dysmenorrhea,
      pcos_value: d.pcos_value
    }

    const otherFr = {
      objective: d.pref_objective,
      origin: checkOriginApplication(d.person_findHiring),
      invited_by: d.invited_by && d.referral_name ? d.referral_name : undefined,
      landmark: d.person_landmark_address,
    }

    setData({
      ...data,
      header: headerFr,
      personal: personalFr,
      education: educationFr,
      family: familyFr,
      work: workFr,
      training: trainingFr,
      medical: medicalFr,
      other: otherFr,
    })
  }

  useEffect(() => {
    formatData()
  }, [details]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="form resume-card">
      <ResumeHeader dt={data.header} />
      <ResumePersonal dt={data.personal} />
      <ResumeEducation dt={data.education} />
      <ResumeFamily dt={data.family} />
      <ResumeWork dt={data.work} />
      <ResumeMedical dt={data.medical} />
      <ResumeOther dt={data.other} />
    </div>
  )
}

import {Button, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {Alert, Modal} from 'react-bootstrap'
import {Notice} from '../../../_metronic/_partials/controls'


//import SecureLS from 'secure-ls'

const useStyles1 = makeStyles(theme => ({
  button: {},
  input: {
    display: 'none',
  },
}))

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
   
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const ModalFinalizeDocument = ({show, onClose}) => {
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const [data, setData] = useState([])


  const [process, setProcess] = useState({
    success: null,
    loading: false,
    error: null,
  })

  
  const handleSubmit = async e => {
    await setProcess({
      loading: true,
    })
  }




  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Set Review</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Notice icon="flaticon-warning font-primary">
            <span>Provide feedback to the document.</span>{' '}
            <span>
              If you encountered a problem. Kindly file a {" "}
              <a
                target="_blank"
                className="font-weight-bold"
                rel="noopener noreferrer"
                href="https://flask.io/8g7bciS8AmUY"
              >
                support ticket
              </a>
            
            </span>
           
          </Notice>
          <div>
            <TextField
              id="outlined-name"
              label="Document Review Message"
              multiline
              rows={5}
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, fname: e.target.value}) : false)}
              required
            />

            <TextField
              id="outlined-select-currency-native"
              select
              label=""
              className={classes2.textField}
              defaultValue={''}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes2.menu,
                },
              }}
              helperText="Type of Store"
              margin="normal"
              variant="outlined"
              onInput={e => (e ? setData({...data, access: e.target.value}) : false)}
              required
            >
              <option value="" disabled>
                Select Status
              </option>
              <option value="7">Failed</option>
              <option value="8">Missing Document</option>
              <option value="6">Transfer to Training</option>
            </TextField>
            
            
            {/* {process.success} */}
            <div className="mx-1">
              <Alert variant={process.success !== null ? (process.success ? 'success' : 'warning') : ''} >
                {process.success === true ? 
                <p className="mb-0">New store created!</p> : 
                (<>
                  {process.success === false ? (
                    <p className="mb-0">{process.error !== null ? process.error : 'There seems to be a problem.'}</p>
                  ) : (
                    ''
                  )}
                  </>
                )
                }
              </Alert>
            </div>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes1.button}
              disabled={process.loading}
            >
              Set Review of Document
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

ModalFinalizeDocument.propTypes = {}

export default ModalFinalizeDocument

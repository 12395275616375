import {getSearchApplicant, getSpecificApplicants} from 'api/private/applicants'
import {Select} from '@material-ui/core'
import PreviewCard from 'components/preview'
import React, {useEffect, useState, useMemo} from 'react'
import DataTable from 'react-data-table-component'
import 'style/_table.scss'
import {useSubheader} from '../../../_metronic/layout'
import SearchApplicantListItem from './SearchApplicantListItem'
import Loader from 'components/preloader'

var dayjs = require('dayjs')
export const SearchApplicantPage = () => {
  const suhbeader = useSubheader()
  suhbeader.setTitle('Search Applicants')
  const [data, setData] = useState({
    applicants: [],
    loading: false,
  })
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const filteredItems = data.applicants
    ? data.applicants.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
    : []

  const [previewData, setPreviewData] = useState([])
  const [year, setYear] = useState('2022')

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const listApplicants = async year => {
    const r = await getSearchApplicant(year)
    setData({loading: true})
    if (!r) return setData({applicants: []})
    let dt = []
    for (let index = 0; index < r.length; index++) {
      let det = JSON.parse(r[index].data)
      dt.push({
        id: r[index].id,
        ref_id: r[index].reference_id,
        applicant_id: r[index].applicant_id,
        email: r[index].username,
        profileImage: r[index].profile,
        createdAt: dayjs(r[index].date_created).format('MMMM-DD-YYYY h:m:s a'),
        company: r[index].company,
        position: r[index].applying_for,
        details: det,
        name: capitalizeName(
          `${det.person_fname} ${
            det.person_mname.length > 0 || det.person_mname.toLowerCase() !== 'n/a'
              ? `${det.person_mname.charAt(0)}.`
              : ''
          } ${det.person_lname}`.toLowerCase(),
        ),
      })
    }
    setYear(year)
    setData({...data, applicants: dt, loading: false})
  }

  useEffect(() => {
    listApplicants('2021')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const previewApplicant = async (_company, _id) => {
    const r = await getSpecificApplicants(_id, _company)
    const data = []
    if (!r) return setPreviewData(data)

    const profile = JSON.parse(r[0].data)
    data.push({
      name: `${profile.person_fname} ${profile.person_mname} ${profile.person_lname}`,
      perm_addr: `$${profile.person_permanent_address_street_blk_pres} ${profile.person_permanent_address_street} ${profile.person_permanent_address_zipcode} ${profile.person_permanent_address_city}`,
      pres_addr: `${profile.person_present_address_street_blk} ${profile.person_present_address_street} ${profile.person_present_address_zipcode} ${profile.person_present_address_city}`,
      profile_img: r[0].profile,
      reference_id: r[0].reference_id,
      email: profile.person_email,
      age: profile.person_age,
      birthdate: dayjs(profile.person_birthdate).format('MMMM-DD-YYYY'),
      contact: profile.person_contact_no_mob,
      sex: profile.person_sex,
      civil_status: profile.person_civil_status,
      religion: profile.person_rel,
      height: profile.person_ht,
      weight: profile.person_wt,
      blood_type: profile.person_bloodtype,
      tin: profile.person_tin,
      sss: profile.person_sss,
      phl: profile.person_phlhealth,
      pagibig: profile.person_pagibig,
      emerg_name: profile.person_emergency_name,
      emerg_contact: profile.person_emergency_contact_no,
      emerg_relationship: profile.person_emergency_relationship,
      nationality: profile.person_nationality,
      id: r[0].id,
      company: r[0].company,
    })
    setPreviewData(data)
  }

  const handleSelectYear = yearSelected => {
    setYear(yearSelected)
    listApplicants(yearSelected)
  }

  const columns = [
    {
      name: 'Applicants',
      selector: 'name',
      cell: row => (
        <SearchApplicantListItem
          image={row.profileImage}
          applicantName={row.name}
          dateCreated={row.createdAt}
          company={row.company}
          id={row.id}
          action={previewApplicant}
        />
      ),
    },
  ]

  const FilterComponent = ({filterText, onFilter, onClear}) => (
    <>
      <input
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        defaultValue={filterText}
        onChange={onFilter}
        key="filterName"
        autoFocus={true}
        className={`form-control w-75 mr-1`}
      />
      <div style={{display: 'block', margin: '16px auto'}}>
        <Select
          native
          label="Year"
          onChange={e => handleSelectYear(e.target.value)}
          defaultValue={year}
          style={{width: '100%', display: 'block'}}
        >
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
        </Select>
      </div>
      <button type="button" className="btn btn-light-success font-weight-bolder font-size-sm" onClick={onClear}>
        X
      </button>
    </>
  )

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    const handleFilter = e => {
      e.preventDefault()
      const d = e.target.value
      setFilterText(d)
    }

    return <FilterComponent onFilter={e => handleFilter(e)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle])

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col table">
              {data.applicants && data.applicants.length > 0 ? (
                <>
                  <DataTable
                    keys="id"
                    columns={columns}
                    progressPending={data.loading}
                    data={filteredItems}
                    initialPageLength={20}
                    pagination
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    className="table"
                  />
                </>
              ) : (
                ' No Data '
              )}
            </div>
            <div className="col" style={{backgroundColor: 'white'}}>
              {previewData.length > 0 ? <PreviewCard previewData={previewData} type={1}></PreviewCard> : ''}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

import {TextField, Button} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import Loader from 'components/preloader'
import {getUserByToken} from 'api/public/auth'
import {getSpecificJob, patchJobRequirements} from 'api/private/jobs'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
// const useStyles1 = makeStyles(theme => ({
//   button: {},
//   input: {
//     display: 'none',
//   },
// }))

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const DetailsJobsExams = () => {
  const classes2 = useStyles2()
  const [data, setData] = useState([])
  const {id, company} = useParams()
  const [process, setProcess] = useState({
    success: null,
    loading: false,
    enableEdit: false,
  })
  const [user, setUser] = useState([])

  const getUser = async () => {
    const r = await getUserByToken()
    if (r && r.status === 200) {
      setUser({...user, ...r.data})
      setData({...data, company: ls.get('company'), created_by: user.id, type: 5, store_address_url: ''})
    }
  }

  const getJob = async () => {
    setProcess({
      loading: true,
    })
    const r = await getSpecificJob(id, company)
    if (!r) return console.error(`Job not found.`)
    const formatData = {}
    formatData.job = r[0].meta_value ? JSON.parse(r[0].meta_value) : {}
    formatData.exams = r[0].exams
      ? r[0].exams.map(exam => {
          return JSON.parse(exam.meta_value)
        })
      : []
    formatData.isUpload = r[0].isUpload[0] ? JSON.parse(r[0].isUpload[0].meta_value) : {}
    formatData.requirements = r[0].requirements[0] ? JSON.parse(r[0].requirements[0].meta_value) : {}
    setData({...data, ...formatData})
    setProcess({
      loading: false,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await setProcess({
      loading: true,
    })
    const r = await patchJobRequirements(data)
    if (r) {
      await setProcess({
        loading: false,
        success: true,
      })
      await setData([])
    } else {
      await setProcess({
        loading: false,
      })
    }
  }

  const handleEdit = (e, _action) => {
    setProcess({enableEdit: _action})
  }

  useEffect(() => {
    getUser()
    getJob()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${process.loading ? `is-loading` : ``}`}>
      {process.enableEdit}
      {!process.loading ? (
        <div>
          <div className="card p-5 w-100">
            {!process.enableEdit ? (
              <Button
                variant="contained"
                className="w-25 ml-auto mb-5"
                color="secondary"
                type="button"
                onClick={e => handleEdit(e, true)}
              >
                Edit details
              </Button>
            ) : (
              <Button
                variant="contained"
                className="w-25 ml-auto mb-5"
                color="secondary"
                type="button"
                onClick={e => handleEdit(e, false)}
              >
                Cancel edit
              </Button>
            )}
            <div>
              <h1 id="contained-modal-title-vcenter">
                {process.enableEdit ? 'Edit' : ''} Jobs - {data.job ? data.job.title : ''}
              </h1>
            </div>

            <div className="mt-10">
              <h3 id="contained-modal-title-vcenter">Current Examinations</h3>
            </div>

            <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" className="mt-3">
              {data.exams
                ? data.exams.map((exam, index) => (
                    <Tab eventKey={index} title={exam.title} key={`tab-${index}`}>
                      <form onSubmit={handleSubmit} className="px-3 ">
                        <div>
                          <TextField
                            id="outlined-name"
                            label="Examination Title"
                            className={classes2.textField}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                            inputProps={{readOnly: !process.enableEdit ? true : ''}}
                            value={exam ? exam.title : ''}
                            // onChange={e => (e ? setData({...data, name: e.target.value}) : false)}
                          />
                          <TextField
                            id="outlined-location"
                            label="Examination Link"
                            className={classes2.textField}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                            value={exam ? exam.link : ''}
                            inputProps={{readOnly: !process.enableEdit ? true : ''}}
                            // onChange={e => (e ? setData({...data, store_address: e.target.value}) : false)}
                          />
                          <TextField
                            rows="3"
                            id="outlined-email"
                            label="Examination Description"
                            className={classes2.textField}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                            value={exam ? exam.notice : ''}
                            inputProps={{readOnly: !process.enableEdit ? true : ''}}
                            // onChange={e => (e ? setData({...data, email: e.target.value}) : false)}
                          />
                          {process.enableEdit ? (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className="m-1"
                                disabled={process.loading}
                              >
                                Remove Examination
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="m-1"
                                disabled={process.loading}
                              >
                                Save Changes
                              </Button>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </form>
                    </Tab>
                  ))
                : ''}
            </Tabs>

            {/* end of examinations */}
          </div>
          <div className="card p-5 mt-5">
            <div className="mt-10">
              <h3 id="contained-modal-title-vcenter">Document Requirements</h3>
            </div>

            <form onSubmit={handleSubmit} className="px-3">
              <label>Details</label>
              <textarea
                rows="20"
                id="outlined-email"
                label="Document Requirements"
                className="form-control"
                InputLabelProps={{shrink: true}}
                value={data.requirements ? data.requirements.requirements : ''}
                inputProps={{readOnly: !process.enableEdit ? true : ''}}
                // onChange={e => (e ? setData({...data, email: e.target.value}) : false)}
              ></textarea>
              {process.enableEdit ? (
                <>
                  <Button variant="contained" color="primary" type="submit" className="m-1" disabled={process.loading}>
                    Save Changes
                  </Button>
                </>
              ) : (
                ''
              )}

              <TextField
                id="outlined-select-currency-native"
                select
                label="-"
                className={classes2.textField}
                defaultValue={data.isUpload ? data.isUpload.status : ''}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes2.menu,
                  },
                }}
                inputProps={{readOnly: !process.enableEdit ? true : ''}}
                helperText="Enable Uploading of Documents"
                margin="normal"
                variant="outlined"
                InputLabelProps={{shrink: true}}
                // onInput={e => (e ? setData({...data, store_type: e.target.value}) : false)}
              >
                <option value="0">Enabled</option>
                <option value="1">Disabled</option>
              </TextField>
              <TextField
                id="outlined-name"
                label="Uploading Email Recipient"
                className={classes2.textField}
                margin="normal"
                variant="outlined"
                InputLabelProps={{shrink: true}}
                inputProps={{readOnly: !process.enableEdit ? true : ''}}
                value={data.isUpload ? data.isUpload.email : ''}
                onChange={e => (e ? setData({...data, name: e.target.value}) : false)}
              />
              {process.enableEdit ? (
                <>
                  <Button variant="contained" color="primary" type="submit" className="m-1" disabled={process.loading}>
                    Save Changes
                  </Button>
                </>
              ) : (
                ''
              )}
            </form>

            <div></div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}
DetailsJobsExams.propTypes = {}
export {DetailsJobsExams}

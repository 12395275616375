import {post, get} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

//const userData = ls.get('ud') ? JSON.parse(ls.get('ud')) : ''
const company = ls.get('company')

const STR_URL = `internal/stores/`
const STR_ACCOUNT_URL = `internal/store/accounts/`
const STR_ACCOUNT_RESET_URL = `internal/system/update/store/account`
const STR_DETAILS_URL = `internal/store/`
const STR_CREATE_ACCOUNT_URL = `internal/system/create/store`
const STR_DEPLOYMENT_APPLICANT = `internal/store/applicant`
const STR_NAME = `internal/store/name`
const STR_UPDATE_APPLICANT = `internal/personnel/store`
const STATUS_UPDATE_APPLICANT = `internal/personnel/status`
const user_details = localStorage.getItem('persist:v713-demo1-auth')

export const getStores = async () => {
  const {user} = JSON.parse(user_details)
  const {user_level} = JSON.parse(user) ? JSON.parse(user) : 0
  const accessLevel = parseInt(user_level)
  let _url = `${STR_URL}${company}/${accessLevel}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getStoreAccounts = async () => {
  let _url = `${STR_ACCOUNT_URL}${company}`

  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getSpecificStore = async (id, company) => {
  let _url = `${STR_DETAILS_URL}/${id}/${company}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const postStoreAccounts = async data => {
  return await post(data, STR_CREATE_ACCOUNT_URL)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return r
      }
    })
    .catch(err => console.error(err))
}

export const postStoreAccountReset = async data => {
  return await post(data, STR_ACCOUNT_RESET_URL)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getApplicantStoreDeploy = async (id, company) => {
  let _url = `${STR_DEPLOYMENT_APPLICANT}/${company}/${id}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const getStoreDetails = async (id) => {
  let _url = `${STR_NAME}/${id}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicantStore = async (data) => {

  return await post(data, STR_UPDATE_APPLICANT)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}

export const updateApplicantStatus = async (data) => {
  return await post(data, STATUS_UPDATE_APPLICANT)
    .then(r => {
      if (r.status === 200) {
        return r
      } else {
        return false
      }
    })
    .catch(err => console.error(err))
}
import React from 'react'
import 'style/_review.scss'

const ResumeOther = ({dt}) => {
  return (
    <div className="form-group">
      <h2>Other Information</h2>
      <hr />

      <table className="other-info">
        <tbody>
          <tr>
            <td width="600" className="font-weight-bold">
              Letter of Intent
            </td>
          </tr>
          <tr>
            <td className="font-weight-bold text-uppercase">{dt.objective} </td>
          </tr>
          <tr>
            <td width="200">Where did you find our hiring?:</td>
          </tr>
          <tr>
            <td className="font-weight-bold text-uppercase">{dt.origin} </td>
          </tr>

          {dt.invited_by !== undefined ? (
            <>
              <tr>
                <td width="200">Where did you find our hiring?:</td>
              </tr>
              <tr>
                <td className="font-weight-bold text-uppercase">{dt.invited_by} </td>
              </tr>
            </>
          ) : (
            <tr></tr>
          )}

          <tr>
            <td width="200">Landmark</td>
          </tr>
          <tr>
            <td width="600">
              <iframe
                title="resumeIframe"
                width="600"
                height="400"
                src={`https://maps.google.de/maps?hl=en&q=${dt.landmark}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                key={`landmark-${dt.name}`}
              ></iframe>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default ResumeOther

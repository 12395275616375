import {
  getCountApplicant, 
  getCountPersonnel, 
  getCountCompleted,
  getCountPending, 
  getCountExamination,
  getCountStoreReview 
} from 'api/private/documents' //sort by female and male on this response
import {getStores} from 'api/private/store'

export default async function getData(success = () => {}, error = () => {}) {
    try {
      let applicants = await getCountApplicant()
      let personnel = await getCountPersonnel()
      let completed = await getCountCompleted()
      let pending = await getCountPending()
      let examination = await getCountExamination()
      let storeReview = await getCountStoreReview()
      let stores = await getStores()
      let male = 0;
      let female = 0;

     /* for(let i = 0; i < applicants.length; i++){
          //parse the meta_value
          let {person_sex} = JSON.parse(applicants[i].data);
          if(person_sex === "female")
            female++
          else if(person_sex === "male")
            male++
      }*/

      success({
          personnel: personnel[0].count, 
          completed: completed[0].count, 
          pending: pending[0].count, 
          examination: examination[0].count, 
          storeReview: storeReview[0].count, 
          male_applicants: male, 
          female_applicants: female,
          applicants: applicants[0].count
      })
    } catch (err) {
        error(err)
    }
}

//import {AirlineSeatLegroomNormal} from '@material-ui/icons'
import {post, get, patch} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

const company = ls.get('company')
const uid = ls.get('uid')

const CREATE_JOB_URL = `internal/system/create/jobs`
const GET_JOBS = `internal/system/jobs/`
const CREATE_EXAM_URL = `internal/system/create/exams`
const JOB_DETAILS_URL = `internal/system/specific/jobs`
const UPDATE_EXAM_URL = `internal/system/update/exams`
const CREATE_JOB_REQUIREMENTS_URL = `internal/system/create/requirements`
const UPDATE_DOCUMENT_REQUIREMENTS = `internal/system/update/requirements`

export const getJobs = async () => {
  let url = `${GET_JOBS}/${company}`

  return await get(url)
    .then(result => {
      if (result.status === 200) return result.data
      else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const createJob = async data => {
  return await post(data, CREATE_JOB_URL)
    .then(result => {
      if (result.status === 200) return result.data
      else console.error('Request timeout')
    })
    .catch(err => console.error(err))
}

export const createExam = async data => {
  data.company = company
  data.id = uid
  return await post(data, CREATE_EXAM_URL)
    .then(result => {
      if (result.status === 200) return result.data
      else console.error('Request timeout')
    })
    .catch(err => console.error(err))
}

export const getSpecificJob = async (id, company) => {
  let _url = `${JOB_DETAILS_URL}/${company}/${id}`
  return await get(_url)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
        return false
      }
    })
    .catch(err => console.error(err))
}

export const patchJobExam = async (id, company) => {
  let _url = `${UPDATE_EXAM_URL}/${id}/${company}`
  return await patch(_url)
    .then(r => {
      console.log(r)
      if (r.status === 200) {
        return r.data
      } else {
        console.error('Request timeout')
      }
    })
    .catch(err => console.error(err))
}

export const postJobRequirements = async data => {
  return await post(data, CREATE_JOB_REQUIREMENTS_URL)
    .then(r => {
      console.log(r)
      if (r.status === 200) {
        return r.data
      } else {
        return undefined
      }
    })
    .catch(err => console.error(err))
}

export const patchJobRequirements = async data => {
  return await post(data, UPDATE_DOCUMENT_REQUIREMENTS)
    .then(r => {
      if (r.status === 200) {
        return r.data
      } else {
        return undefined
      }
    })
    .catch(err => console.error(err))
}

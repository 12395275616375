import React from 'react'
//import PropTypes from 'prop-types'

const SettingsPage = props => {
    return (
        <div>

        </div>
    )
}

SettingsPage.propTypes = {

}

export { SettingsPage }

import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import DatePicker from "react-datepicker";
import {CircularProgress, TextField, Box, Divider, Typography} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {Modal, Form, Button} from 'react-bootstrap'
import DialogModal from 'components/dialog'
import moment from 'moment'
import {
  generateUploadUrl,
  postUploadDocument,
  postDocumentChange,
  postUpdateDocumentPending,
  postUpdateDocumentTraining,
  getSanctionsList,
  transferTraining
} from 'api/private/documents'
import {getApplicantStoreDeploy} from 'api/private/store'
import {updateApplicantProfile, updateApplicationPhone, updateApplicationGov} from 'api/private/applicants'
import {postHrPersonnel} from 'api/private/recruitment'
import {getDocuments, getSpecificDocuments, getSearchSpecificDocuments} from 'api/private/documents'
import 'style/_table.scss'
import SecureLS from 'secure-ls'
import {getStores} from 'api/private/store'
var dayjs = require('dayjs')

const ls = new SecureLS({encodingType: 'aes'})

const PreviewCard = ({previewData, type, pageType}) => {
  const pathname = window.location.href.split('/')
  const company_author = ls.get('company')
  const uid = ls.get('uid')
  const [isDocument, setDocument] = useState(false)
  const [show, setShow] = useState(false)
  const [dateShow, setDateShow] = useState(false)
  const [returned, setReturned] = useState(false)
  const [updateProfile, setUpdateProfile] = useState(false)
  const [updatePhone, setUpdatePhone] = useState(false)
  const [updateGov, setUpdateGov] = useState(false)
  const [storeDeployment, setStoreDeployment] = useState('')
  const [store, setStore] = useState('')
  // const [sanctions, setSanctions] = useState([])
  const [updateSanction, setUpdateSanction] = useState()
  const [storeId, setStoreId] = useState('')
  const [newFirstName, setNewFirstName] = useState('')
  const [newMiddleName, setNewMiddleName] = useState('')
  const [newLastName, setNewLastName] = useState('')
  const [newPhone, setNewPhone] = useState('')
  const [newTin, setNewTin] = useState('')
  const [newPhilhealth, setNewPhilhealth] = useState('')
  const [newSss, setNewSss] = useState('')
  const [newPagibig, setNewPagibig] = useState('')
  const [newStoreId, setNewStoreId] = useState('')
  const [stores, setStores] = useState([])
  const [upload, setUpload] = useState([
    {
      modal: '',
      inptDocType: '',
      show: false,
      docid: '',
      id: '',
      doctype: '',
      url: '',
      company: '',
      blobImg: '',
      imgFile: '',
      loading: false,
      success: false,
    },
  ])
  const [sanctions, setSanctions] = useState([
    {
      sanctionName: '',
      details: [
        {
          remarks: '',
          section: '',
          file:''
        }
      ]
    }
  ]);

  
  const [isUploading, setButtonUploadState] = useState(false)

  const [modal, setModal] = useState([
    {
      show: false,
      id: '',
      applicant_id: '',
      type: '',
    },
  ])
  const [update, setUpdateStatus] = useState([
    {
      modal: false,
      loading: false,
      success: false,
      appl_id: '',
      appl_company: '',
      notice: '',
      status: '',
    },
  ])

  const [hr, setHr] = useState([
    {
      modal: false,
      loading: false,
      success: false,
      appl_id: '',
      appl_company: '',
      store_id: '',
      appl_name: '',
      status: '',
    },
  ])

  const [dateHired, setDateHired] = useState(new Date());
  const [violationData, setViolationData] = useState([])
  const [violationModal, setViolationModal] = useState(false)
  const [newListViolation, setNewListViolation] = useState('')

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const data =
    previewData.length > 0
      ? previewData[0]
      : {
          reference_id: '',
          civil_status: '',
          contact: '',
          email: '',
          age: '',
          birthdate: '',
          emerg_contact: '',
          emerg_name: '',
          emerg_relationship: '',
          height: '',
          name: '',
          pagibig: '',
          perm_addr: '',
          phl: '',
          pres_addr: '',
          profile_img: '',
          religion: '',
          sex: '',
          sss: '',
          tin: '',
          weight: '',
          nationality: '',
          id: '',
          company: '',
          firstName: '',
          middleName: '',
          lastName: ''
        }

  const {
    reference_id,
    civil_status,
    contact,
    email,
    age,
    birthdate,
    emerg_contact,
    emerg_name,
    emerg_relationship,
    height,
    name,
    pagibig,
    perm_addr,
    phl,
    pres_addr,
    profile_img,
    religion,
    sex,
    sss,
    tin,
    weight,
    nationality,
    id,
    company,
    firstName,
    middleName,
    lastName
  } = data


  const handleRemove = async (id, applicant_id, status) => {
    setModal(previousState => ({...previousState, show: true, id: id, applicant_id: applicant_id, type: status}))
  }

  const handleCloseDialog = async () => {
    setModal(previousState => ({...previousState, show: false, id: '', applicant_id: '', type: ''}))
  }

  const handleChangeStats = async () => {
    const r = await postDocumentChange({
      id: modal.id,
      applicant_id: modal.applicant_id,
      type: modal.type,
    })
    if (r) {
      const s = await getSpecificDocuments(data.id, 'all')
      data.documents = s
      alert("Document successfuly archived")
      setModal({...modal, show: false, id: '', applicant_id: '', type: ''})
    }
  }

  const handleSubmitFile = async e => {
    e.preventDefault()
    if (!upload.loading) {
      setButtonUploadState(true)
      const r = await postUploadDocument(
        upload.blobImg,
        upload.imgFile,
        upload.url,
        upload.id,
        upload.company,
        upload.doctype,
        upload.docid,
      )
      if (r) {
        setButtonUploadState(false)
        setUpload({
          modal: '',
          inptDocType: '',
          docid: '',
          id: '',
          doctype: '',
          url: '',
          company: '',
          blobImg: '',
          imgFile: '',
          loading: false,
          success: true,
        })
        alert("Upload Success wait for the page to refresh before continuing")
        setTimeout(function() {
          setButtonUploadState(false)
          window.location.reload()
        }, 5000)
      }
    }
  }

  const handleFileChange = async e => {
    setButtonUploadState(true)
    setUpload({...upload, loading: true})
    const _file = e.target.files[0]
    const _blob = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

    const _url = await generateUploadUrl(_blob, _file)
    setUpload({...upload, url: _url, blobImg: _blob, imgFile: _file, loading: false})
    setButtonUploadState(false)
  }

  const handleUpload = (id, doctype, appl_id, company) => {
    setUpload({...upload, show: true, docid: id, doctype: doctype, id: appl_id, company: company})
    setButtonUploadState(false)
  }

  const handleClose = () => {
    setButtonUploadState(false)
    setUpload({...upload, show: false})
  }

  const handleUpdateStatus = async (event, id, company, name) => {
    const storeDeployment = await getApplicantStoreDeploy(id, company)
    if (storeDeployment === false) {
      setStoreDeployment("No store deployment")
      setStoreId(null)
    }
    else {
      setStoreDeployment(storeDeployment[0].store_name)
      setStoreId(storeDeployment[0].store)  
    }
    event.preventDefault()
    if (type == "3") {
      setHr({...hr, appl_id: id, appl_company: company, appl_name: name, store_id: storeDeployment[0].store})
      setUpdateStatus({...update, modal: true})  
    }
    else {
      setUpdateStatus({...update, appl_id: id, appl_company: company, modal: true}) 
    }
    
  }

  const handleUpdateStatusSubmit = async e => {
    e.preventDefault()
    const formattedDate = moment(dateHired).format('YYYY-MM-DD');
    if (type == "3") {
      const newPersonnel = await postHrPersonnel({
        aplid: hr.appl_id,
        apl_name: hr.appl_name,
        storeid: newStoreId ? newStoreId : hr.store_id,
        status: hr.status,
        company: hr.appl_company, 
        date: formattedDate
      })
      if (newPersonnel.status === 200) {
        setHr({
          ...hr,
          loading: false,
          id: '',
          company: '',
          appl_id: '',
          appl_company: '',
          store_id: '',
          appl_name: '',
          status: '',
        })

        setUpdateStatus({...update, success: true})

        setTimeout(function() {
          setUpdateStatus({...update, modal: false})
          window.location.reload()
        }, 1000)  
      }
    }
    else {
      setUpdateStatus({...update, loading: true})
      var r = ''
      if (pageType === 'pending') {
        r = await postUpdateDocumentPending({
          appl_id: update.appl_id,
          appl_company: update.appl_company,
          notice: update.notice,
          status: update.status,
        })
      } else if (pageType === 'complete') {
        r = await transferTraining(update.appl_id, update.status)
        /*r = await postUpdateDocumentTraining({
          appl_id: update.appl_id,
          appl_company: update.appl_company,
          notice: update.notice,
          status: update.status,
        })*/
      }

      if (r) {
        setUpdateStatus({
          ...update,
          loading: false,
          success: true,
          id: '',
          company: '',
          appl_id: '',
          apply_company: '',
          notice: '',
          status: '',
        })

        setTimeout(function() {
          setUpdateStatus({...update, modal: false})
          window.location.reload()
        }, 1000)
      }  
    }
    
  }

  const handleUpdateStatusClose = () => {
    setUpdateStatus({
      ...update,
      id: '',
      company: '',
      appl_id: '',
      appl_company: '',
      notice: '',
      status: '',
      modal: false,
      loading: false,
      success: false,
    })
  }

  const listStores = async () => {
    const r = await getStores()
    if (r && r.length > 0) {
      setStores(r)
    }
  }

  const handleStoreChange = (e) => {
    setStore(e.target.value)
    setNewStoreId(e.target.value)
  }

  const handleStoreChangeClick = (e) => {
    if (e.target.textContent === "Change") {
      e.target.textContent = "Cancel"
      setShow(true)
    }
    else {
      e.target.textContent = "Change"
      setNewStoreId('')
      setShow(false)
    }
  }
  
  const handleHrStatusChange = (e) => {
    setHr({...hr, status: e.target.value})
    if (e.target.value == 2) {
      setReturned(true)
    }
    else {
      setReturned(false)
    }
    
  }

  const getSanctions = async () => {
    try {
      const sanctionDetails = await getSanctionsList(data.id)
      if(sanctionDetails){
        setSanctions(prevSanctions => {
          const newSanctions = [...prevSanctions];
    
          sanctionDetails.forEach((sanctionDetail) => {
            const existingSanctionIndex = newSanctions.findIndex(sanction => sanction.sanctionName === sanctionDetail.sanction);
    
            if (existingSanctionIndex === -1) {
              // If the sanctionName doesn't exist, add a new sanction
              newSanctions.push({
                sanctionName: sanctionDetail.sanction,
                details: [
                  {
                    remarks: sanctionDetail.remarks,
                    section: sanctionDetail.section,
                    file: sanctionDetail.file
                  }
                ]
              });
            } else {
              // If the sanctionName exists, update the details for that sanction
              newSanctions[existingSanctionIndex].details.push({
                remarks: sanctionDetail.remarks,
                section: sanctionDetail.section,
                file: sanctionDetail.file
              });
            }
          });
    
          return newSanctions;
        });
      }
  
    } catch (error) {
      console.error('Error fetching sanctions:', error);
    }
  };

  useEffect(() => {
    if (pathname[pathname.length - 1] === 'pending' || pathname[pathname.length - 1] === 'complete') {
      setDocument(true)
    }
  }, [pathname])

  useEffect(() => {
    getSanctions()
    listStores()
  }, [])

  const onHandleClick = async () => {
    setNewFirstName(firstName)
    setNewMiddleName(middleName)
    setNewLastName(lastName)
    setUpdateProfile(true)
  }

  const onHandlePhoneClick = async () => {
    setNewPhone(contact)
    setUpdatePhone(true)
  }

  const onHandleGovIdClick = async () => {
    setNewTin(tin)
    setNewPhilhealth(phl)
    setNewSss(sss)
    setNewPagibig(pagibig)
    setUpdateGov(true)
  }

  const handleUpdateProfileClose = () => {
    setUpdateProfile(false)
  }

  const handleUpdatePhoneClose = () => {
    setUpdatePhone(false)
  }

  const handleUpdateGovClose = () => {
    setUpdateGov(false)
  }

  const handleUpdateProfileSubmit = async (e) => {
    e.preventDefault()
    const data = {
      firstName: newFirstName,
      oldFirstName: firstName,
      middleName: newMiddleName,
      oldMiddleName: middleName,
      lastName: newLastName,
      oldLastName: lastName,
    }
    const result = await updateApplicantProfile(id, data)
    if (result.status === 200) {
      alert("Profile update successfull")
      window.location.reload() 
    }
  }

  const handleUpdatePhoneSubmit = async (e) => {
    e.preventDefault()
    const data = {
      phone: newPhone,
      oldPhone: contact
    }
    const result = await updateApplicationPhone(id, data)
    if (result.status === 200) {
      alert("Profile update successfull")
      window.location.reload() 
    }
  }

  const handleUpdateGovSubmit = async (e) => {
    e.preventDefault()

    const data = {
      tin: newTin,
      oldTin: tin,
      phil: newPhilhealth,
      oldPhil: phl,
      sss: newSss,
      oldSss: sss,
      pagibig: newPagibig,
      oldPagibig: pagibig,
    }
    const result = await updateApplicationGov(id, data)
    if (result.status === 200) {
      alert("Profile update successfull")
      window.location.reload() 
    }
  }
  let files = []
  const handleViolationFiles = (key) =>{
    files.push(key)
  }
  const handleViolationModal = (key) =>{
    setViolationModal(true)
    setNewListViolation(key)
  }
  const handleViolationModalClose = () =>{
    setViolationModal(false)
  }

  // useEffect(() =>{
    
  // },[files])

  const uniqueRows = new Set();
  return (
    <div className="form preview-card">
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className="mt-5 text-center">
          <div className="symbol symbol-60 symbol-xxl-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${profile_img})`,
              }}
            ></div>
          </div>
          <div>
            <span href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
              {capitalizeName(name.toLowerCase())} <img src="https://icon-library.com/images/white-edit-icon/white-edit-icon-2.jpg" onClick={onHandleClick} width={12} height={12}/>
            </span>
            <div className="mt-2">
              {type || pageType === 'pending' || pageType === 'complete' ? (
                <>
                  {type === 1 && !isDocument ? (
                    <Link
                      to={`/applicant/review/${id}/${company}`}
                      target="_blank"
                      className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    >
                      Review Application
                    </Link>
                  ) : (
                    <Link
                      to={`/applicant/view/${id}/${company}`}
                      target="_blank"
                      className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    >
                      View Application
                    </Link>
                  )}
                </>
              ) : (
                <Link
                  to={`/applicant/view/${id}/${company}`}
                  target="_blank"
                  className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                >
                  View Application
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <table className="row">
        <tbody className="form-group row">
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Reference Number:</td>
            <td className="font-weight-bold">{reference_id}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Email:</td>
            <td className="font-weight-bold">{email}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>
              Contact Number: 
            </td> 
            <td className="font-weight-bold">{contact} <img src="https://icon-library.com/images/white-edit-icon/white-edit-icon-2.jpg" onClick={onHandlePhoneClick} width={12} height={12}/></td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Age:</td>
            <td className="font-weight-bold">{age}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Birth Date:</td>
            <td className="font-weight-bold">{birthdate}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Permanent Address:</td>
            <td className="font-weight-bold">{perm_addr}</td>
          </tr>
          <tr className="col-xl-7 col-lg-7 col-form-label">
            <td>Present Address:</td>
            <td className="font-weight-bold">{pres_addr}</td>
          </tr>
        </tbody>
      </table>

      {pageType === 'pending' || pageType === 'complete' ? (
        <button
          className="btn btn-light-primary font-weight-bolder font-size-sm mx-2"
          onClick={e => handleUpdateStatus(e, id, company, name)}
        >
          Update Applicant Status <i className="fas fa-edit ml-2"></i>
        </button>
      ) : (
        ''
      )}

      <div className="separator separator-dashed my-7"></div>
      <div className="row">
        <table className="col">
          <tbody className="form-group row">
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Sex:</td>
              <td className="font-weight-bold">{capitalizeName(sex)}</td>
            </tr>
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Civil Status:</td>
              <td className="font-weight-bold">{capitalizeName(civil_status)}</td>
            </tr>
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Religion:</td>
              <td className="font-weight-bold">{religion}</td>
            </tr>
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Citizenship:</td>
              <td className="font-weight-bold">{nationality}</td>
            </tr>
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Height:</td>
              <td className="font-weight-bold">{height}</td>
            </tr>
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Weight:</td>
              <td className="font-weight-bold">{weight}</td>
            </tr>
          </tbody>
        </table>

        <table className="col">
          <tbody className="form-group row">
            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td className="font-weight-bold d-block">Government ID's <img src="https://icon-library.com/images/white-edit-icon/white-edit-icon-2.jpg" onClick={onHandleGovIdClick} width={12} height={12}/></td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>TIN:</td>
              <td className="font-weight-bold">{tin}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>PHILHEALTH:</td>
              <td className="font-weight-bold">{phl}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>SSS:</td>
              <td className="font-weight-bold">{sss}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>PAGIBIG:</td>
              <td className="font-weight-bold">{pagibig}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td className="font-weight-bold d-block">Emergency Contact</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Name:</td>
              <td className="font-weight-bold">{emerg_name}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Contact Number:</td>
              <td className="font-weight-bold">{emerg_contact}</td>
            </tr>

            <tr className="col-xl-7 col-lg-7 col-form-label">
              <td>Relationship:</td>
              <td className="font-weight-bold">{emerg_relationship}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        {data.documents && data.documents.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th width="160">Name</th>
                <th width="100">Date Created</th>
                <th width="300"></th>
              </tr>
            </thead>
            <tbody>
              {data.documents &&
                data.documents.map((v, k) => {
                  return (

                    <tr key={k}>
                      {parseInt(v.status) === 0 
                        ? 
                          <>
                            <td width="160" className="text-uppercase">
                              {v.doctype.replace('-', ' ')}
                            </td>
                            <td width="100" className="px-3">
                              {dayjs(v.created).format('MMM-DD-YY')}
                            </td>
                            <td width="300" className="text-right">
                              <a
                                href={v.url}
                                className="btn btn-light-success font-weight-bolder font-size-sm"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-eye"></i>
                              </a>
                              <button
                                className="btn btn-light-success font-weight-bolder font-size-sm ml-2"
                                onClick={() => handleRemove(v.id, v.applicant_id, 1)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                              {pageType === 'pending' ? (
                                <>
                                  {parseInt(v.status) === 0 ? (
                                    <button
                                      className="btn btn-light-primary font-weight-bolder font-size-sm mx-2 w-50"
                                      onClick={() => handleUpload(v.id, v.doctype, v.applicant_id, v.company)}
                                    >
                                      Re-Upload <i className="fas fa-upload font-size-sm ml-2"></i>
                                    </button>
                                  ) : (
                                    <div className="btn btn-light-success font-weight-bolder font-size-sm mx-2 cursor-default w-50">
                                      Previous File
                                    </div>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          </>
                        :
                          ''
                      }
                    </tr>
                  )
                })}
                {type === 5 
                  ?
                  <>
                  <Divider width="500" />
                  <tr>
                    <td width="300" className="text-uppercase w-100 text-center font-weight-bold" >
                      INFRACTIONS AND OTHER VIOLATIONS
                    </td>

                    {sanctions.length > 0
                      ?
                        sanctions.map(data => {
                          if(data.sanctionName != ''){
                            return (
                              <>
                                <br/>
                                <th width="180" className="text-center">{data.sanctionName}</th>
                                <th width="300" className="text-center">Remarks</th>
                                <th className='text-center'></th>
                                <tr>
                                  {
                                    data.details.map((detail, index) => {
                                      const key = `${detail.section}-${detail.remarks}`;
                                      const newViolationData = {
                                          url: detail.file,
                                          fileName: detail.remarks,
                                          key: key
                                        };
                                      handleViolationFiles(newViolationData)
                                      if(!uniqueRows.has(key)){
                                        uniqueRows.add(key)
                                        return(
                                          <tr key={index}>
                                          <td width="180">{detail.section}</td>
                                          <td width="300" className="text-center">{detail.remarks}</td>
                                          <td className="text-center">
                                            <a
                                              onClick={() => handleViolationModal(key)}
                                              className="btn btn-light-success font-weight-bolder font-size-sm"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fas fa-eye"></i>
                                            </a>
                                          </td>
                                          </tr>
                                        )
                                      }
                                      return null;
                                      
                                    })
                                  }
                                </tr>
                                <th width="300"></th>
  
                          
                              </>
                            )
                          }
                        })
                      :
                      ''
                    }
                  </tr>
                  </> 
                  :
                  ''
                }
                
            </tbody>
          </table>
        ) : (
          <div className="text-center d-flex justify-content-center align-items-center h-100 px-5 py-10">
            {isDocument ? <h4>No Documents uploaded yet.</h4> : ''}
          </div>
        )}
        <Modal size="md" show={upload.show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header>
            <h3>Re-upload File</h3>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <form onSubmit={handleSubmitFile}>
              <input type="hidden" name="company" value={upload.company} />
              <input type="hidden" name="id" value={upload.id} />
              <input type="hidden" name="doc_id" value={upload.docid} />
              <input className="form-control" type="file" name={upload.doctype} onChange={e => handleFileChange(e)} />
              {upload.success ? (
                <Alert className="my-2" iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" />}}>
                  File uploaded successfully.
                </Alert>
              ) : (
                ''
              )}
              <button
                className="btn btn-light-primary font-weight-bolder font-size-sm mt-5 float-right w-50"
                type="submit"
                disabled={isUploading}
              >
                {isUploading ? <CircularProgress color="primary" size={20} /> : 'Upload'}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <DialogModal
          show={modal.show || false}
          hide={handleCloseDialog}
          submit={handleChangeStats}
          title={`Document Action`}
          content={`Do you wish to archive this document?`}
          _type={`confirmation`}
        ></DialogModal>
        <Modal
          size="md"
          show={update.modal}
          onHide={handleUpdateStatusClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
          {type === 3 
            ? 
            <h3>Transfer Applicant to Personnel</h3>
            :
            <h3>Change Applicant's Document Details</h3>
          }
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <form onSubmit={e => handleUpdateStatusSubmit(e)}>
              <input type="hidden" name="company" value={company_author} />
              <input type="hidden" name="id" value={uid} />
              <input type="hidden" name="appl_id" value={update.appl_id} />
              <input type="hidden" name="appl_company" value={update.appl_company} />
              <input type="hidden" name="status" value={update.status} />
              {type != 3 
                ? 
                  <>
                    <div className="form-group">
                      <textarea
                        name="notice"
                        className="form-control"
                        rows="10"
                        onInput={e => (e ? setUpdateStatus({...update, notice: e.target.value}) : false)}
                      />
                    </div>

                    <div className="form-group">
                      <select
                        className="form-control"
                        name="status"
                        onChange={e => (e ? setUpdateStatus({...update, status: e.target.value}) : false)}
                      >
                        <option value="">Select Status</option>
                        {pageType === 'pending' ? (
                          <>
                            <option value="0">Passed</option>
                            <option value="1">Failed</option>
                            <option value="2">Missing document</option>
                          </>
                        ) : (
                          <>
                            <option value="1">Failed</option>
                            <option value="2">Missing document</option>
                            {type != 5 && previewData[0].status == 1 
                              ?
                                <option value="returned">Transfer to Training</option> 
                              :
                               ''
                            }
                            
                          </>
                        )}
                      </select>
                    </div>
                  </>
                :
                  <div className="form-group">
                    <select
                      className="form-control mb-5"
                      name="status"
                      onChange={e => handleHrStatusChange(e)}
                      required
                    >
                      <option value="">Select Status</option>
                        <>
                          <option value="HIRED">HIRED</option>
                          <option value="2">RETURN TO RECRUITMENT</option>
                        </>
                    </select>
                    <span hidden={returned}>Deployment Store -> {storeDeployment} 
                      <button
                        className="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                        type="button"
                        onClick={handleStoreChangeClick}
                        disabled={update.loading}
                      >
                      Change
                      </button>
                    </span>
                    <select
                      name="status"
                      className={`form-control mt-5`}
                      onChange={e => handleStoreChange(e)}
                      value={store}
                      required={show}
                      style={{ display: (show ? 'block' : 'none') }}
                      
                    >
                      <option value="">Select Store Deployment</option>
                      {stores.map((v, k) => {
                          return (
                            <option key={k} value={v.id}>
                              {capitalizeName(v.name)}
                            </option>
                          )
                        })}
                    </select>
                    <div className="mt-5">
                      <span hidden={returned}>
                        Date of Contract Signing: <DatePicker className="font-size-sm form-control w-100 xs-12" selected={dateHired} onChange={(date) => setDateHired(date)}/>
                      </span>
                    </div>
                  </div>
              }

              {update.success ? (
                <Alert className="my-2" iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" />}}>
                  Applicant's Status changed success.
                </Alert>
              ) : (
                ''
              )}
              <button
                className="btn btn-primary font-weight-bolder font-size-sm mt-5 float-right w-100"
                type="submit"
                disabled={update.loading}
              >
                Submit
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={updateProfile}
          onHide={handleUpdateProfileClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <h3>Update Applicant Details</h3>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form onSubmit={e => handleUpdateProfileSubmit(e)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" value={newFirstName} onChange={(e) => setNewFirstName(e.target.value)} placeholder="Enter email" />
                <Form.Label>Middle Name</Form.Label>
                <Form.Control type="text" value={newMiddleName} onChange={(e) => setNewMiddleName(e.target.value)} placeholder="Enter email" />
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" value={newLastName} onChange={(e) => setNewLastName(e.target.value)} placeholder="Enter email" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={updatePhone}
          onHide={handleUpdatePhoneClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <h3>Update Applicant Details</h3>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form onSubmit={e => handleUpdatePhoneSubmit(e)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} placeholder="Enter Phone" />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={updateGov}
          onHide={handleUpdateGovClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <h3>Update Applicant Details</h3>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Form onSubmit={e => handleUpdateGovSubmit(e)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Tin</Form.Label>
                <Form.Control type="text" value={newTin} onChange={(e) => setNewTin(e.target.value)} placeholder="Enter Tin" />
                <Form.Label>Philhealth</Form.Label>
                <Form.Control type="text" value={newPhilhealth} onChange={(e) => setNewPhilhealth(e.target.value)} placeholder="Enter Philhealth" />
                <Form.Label>SSS</Form.Label>
                <Form.Control type="text" value={newSss} onChange={(e) => setNewSss(e.target.value)} placeholder="Enter SSS" />
                <Form.Label>Pagibig</Form.Label>
                <Form.Control type="text" value={newPagibig} onChange={(e) => setNewPagibig(e.target.value)} placeholder="Enter Pagibig" />
                
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={violationModal}
          onHide={handleViolationModalClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <h3>Violation File list</h3>
          </Modal.Header>
          <Modal.Body className="overlay overlay-block cursor-default">
            <Typography variant='body1'> Click the button to view a file</Typography>
            {files && files.map((data) =>{
              if(data.key === newListViolation){
                return(
                  <button className="btn btn-light-success font-weight-bolder font-size-sm mt-5">
                    <a href={data.url} target="_blank" rel="noopener noreferrer"> {data.key} </a>
                  </button>
                )
              }
            })}
          </Modal.Body>
          <Modal.Footer>
          <button
                className="btn btn-primary font-weight-bolder font-size-sm mt-5 float-right w-100"
                disabled={update.loading}
                onClick={handleViolationModalClose}
              >
                Close
              </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default PreviewCard

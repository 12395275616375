import React, { useState } from 'react'
import { Modal, Alert } from 'react-bootstrap'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createExam } from 'api/private/jobs'
//import SecureLS from 'secure-ls'
//const ls = new SecureLS({encodingType: 'aes'})
const useStyles1 = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}))

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const CreateNewExams = ({ show, onClose, jobs }) => {
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const [data, setData] = useState({})
  const [process, setProcess] = useState({
    loading: false,
    success: null,
  })

  const handleSubmit = async e => {
    e.preventDefault()
    await setProcess({
      loading: true,
    })
    const r = await createExam(data)
    if (r) {
      await setProcess({
        loading: false,
        success: true,
      })
      await setData([])
    } else {
      await setProcess({
        loading: false,
      })
    }

    setTimeout(onClose, 700)
  }

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Create Exam for Jobs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-select-currency-native"
            select
            label="-"
            className={classes2.textField}
            // value={values2.currency}
            onChange={e => (e ? setData({ ...data, job_id: e.target.value }) : false)}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes2.menu,
              },
            }}
            helperText="Job Title"
            margin="normal"
            variant="outlined"
          >
            <option selected disabled>
              -
            </option>
            {jobs.map((d, k) => {
              return (
                <option key={k} value={d._id}>
                  {d.title}
                </option>
              )
            })}
          </TextField>
          <TextField
            id="outlined-name"
            label="Examination Title"
            className={classes2.textField}
            // value={values2.name}
            onChange={e => (e ? setData({ ...data, title: e.target.value }) : false)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-name"
            label="Examination Link (eg. Google Form Link)"
            className={classes2.textField}
            // value={values2.name}
            onChange={e => (e ? setData({ ...data, link: e.target.value }) : false)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="outlined-name"
            label="Examination notice"
            className={classes2.textField}
            // value={values2.name}
            onChange={e => (e ? setData({ ...data, notice: e.target.value }) : false)}
            margin="normal"
            variant="outlined"
          />
          <div className="mx-1">
            {process.success !== null ? (
              <Alert variant={process.success === true ? 'success' : 'danger'}>
                {process.success === true ? (
                  <p className="mb-0">New exam created!</p>
                ) : (
                  <p className="mb-0">There seems to be a problem.</p>
                )}
              </Alert>
            ) : (
              ''
            )}
          </div>
          <Button
            variant="contained"
            type="submit"
            disabled={process.loading}
            color="secondary"
            className={classes1.button}
          >
            Create Examination
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

CreateNewExams.propTypes = {}

export default CreateNewExams

import {Button, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {Alert, Modal} from 'react-bootstrap'
import {Notice} from '../../../_metronic/_partials/controls'

import {getUserByToken} from 'api/public/auth'
import {postPeopleAccounts} from 'api/private/people'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
const useStyles1 = makeStyles(theme => ({
  button: {},
  input: {
    display: 'none',
  },
}))

const useStyles2 = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {},
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}))

const AddNewPeopleForm = ({show, onClose}) => {
  const classes1 = useStyles1()
  const classes2 = useStyles2()
  const [data, setData] = useState([])

  const [process, setProcess] = useState({
    success: null,
    loading: false,
    error: null,
  })

  const [user, setUser] = useState([])

  const getUser = async () => {
    const r = await getUserByToken()
    if (r.status === 200) {
      setUser({...user, ...r.data})
      setData({...data, company: ls.get('company'), id: ls.get('uid')})
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    await setProcess({
      loading: true,
    })
    console.log(data)
    const r = await postPeopleAccounts(data)
    if (r.status === 200) {
      await setProcess({
        loading: false,
        success: true,
      })
      await setData({
        fname: '',
        lname: '',
        email: '',
        access: '',
      })
    } else {
      if (r.status === 423) {
        await setProcess({
          loading: false,
          success: false,
          error: r.message,
        })
      } else {
        await setProcess({
          loading: false,
          success: false,
        })
      }
    }

    onClose = true
  }

  useEffect(() => {
    getUser()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add new people</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Notice icon="flaticon-warning font-primary">
            <span>Provide context of the store, Note the password will be sent to the provided email address.</span>{' '}
            <span>
              If you encountered a problem. Kindly file a{' '}
              <a
                target="_blank"
                className="font-weight-bold"
                rel="noopener noreferrer"
                href="https://flask.io/8g7bciS8AmUY"
              >
                support ticket
              </a>
            </span>
          </Notice>
          <div>
            <TextField
              id="outlined-name"
              label="First name"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, fname: e.target.value}) : false)}
              required
            />
            <TextField
              id="outlined-location"
              label="Last name"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, lname: e.target.value}) : false)}
              required
            />
            <TextField
              id="outlined-email"
              label="Email address"
              className={classes2.textField}
              margin="normal"
              variant="outlined"
              onChange={e => (e ? setData({...data, email: e.target.value}) : false)}
              required
            />

            <TextField
              id="outlined-select-currency-native"
              select
              label="-"
              className={classes2.textField}
              defaultValue={''}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes2.menu,
                },
              }}
              helperText="Type of Store"
              margin="normal"
              variant="outlined"
              onInput={e => (e ? setData({...data, access: e.target.value}) : false)}
              required
            >
              <option value="" disabled>
                Access Level
              </option>
              <option value="2">Employee</option>
              <option value="6">Recruitment Manager</option>
              <option value="7">Finance Manager</option>
              <option value="8">Training Manager</option>
              <option value="4">Recruitment Head</option>
              <option value="5">Team Supervisor</option>
              <option value="3">Administrator</option>
            </TextField>

            {/* {process.success} */}
            <div className="mx-1">
              <Alert variant={process.success !== null ? (process.success ? 'success' : 'warning') : ''}>
                {process.success === true ? (
                  <p className="mb-0">New store created!</p>
                ) : (
                  <>
                    {process.success === false ? (
                      <p className="mb-0">{process.error !== null ? process.error : 'There seems to be a problem.'}</p>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </Alert>
            </div>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes1.button}
              disabled={process.loading}
            >
              Save Entry
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

AddNewPeopleForm.propTypes = {}

export default AddNewPeopleForm

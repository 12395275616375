import React, {useEffect} from 'react'
import {useState} from 'react'
import AddNewStoreForm from './AddNewStoreForm'
import Table from 'react-bootstrap-table-next'
import PaginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit'
import {Button} from '@material-ui/core'
import {getStores} from 'api/private/store'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
var dayjs = require('dayjs')
const {SearchBar} = Search
const useStyles1 = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}))

const StoreListPage = props => {
  const classes1 = useStyles1()

  const capitalizeName = str => {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const [show, setShow] = useState(false)
  const [data, setData] = useState({
    stores: [],
    loading: false,
  })

  const listStores = async () => {
    const r = await getStores()
    setData({...data, loading: true})
    if (r) {
      let dt = []
      r.map(d =>
        dt.push({
          id: d.id,
          store_name: capitalizeName(d.name.toLowerCase()),
          company: d.company,
          createdAt: dayjs(d.date_created).format('MMMM-DD-YYYY h:m:s a'),
        }),
      )
      setData({...data, stores: dt, loading: false})
    }
  }

  useEffect(() => {
    listStores()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ]

  const columns = [
    {
      dataField: 'id',
      text: '-',
      sort: true,
      Cell: row => (
        <div>
          <span title={row.value}>{row.value}</span>
        </div>
      ),
    },
    {
      dataField: 'store_name',
      text: 'Store Name',
    },
    {
      dataField: 'createdAt',
      text: 'Date Posted',
    },
    {
      dataField: 'df1',
      text: '',
      formatter: (cellContent, row) => (
        <Link type="button" to={`/store/details/${row.id}/${row.company}`} className="btn btn-primary">
          View details
        </Link>
      ),
    },
  ]

  return (
    <div>
      <div>
        <ToolkitProvider
          keyField="id"
          data={data.stores}
          columns={columns}
          search
          defaultSorted={defaultSorted}
          pagination={PaginationFactory()}
        >
          {props => (
            <div>
              <SearchBar {...props.searchProps} />
              <Button variant="contained" color="secondary" className={classes1.button} onClick={handleShow}>
                Add new store
              </Button>
              <hr />
              <Table defaultSorted={defaultSorted} pagination={PaginationFactory()} {...props.baseProps} />
            </div>
          )}
        </ToolkitProvider>
      </div>

      <AddNewStoreForm show={show} onClose={handleClose} />
    </div>
  )
}

StoreListPage.propTypes = {}

export {StoreListPage}

import React from 'react'
import PropTypes from 'prop-types'
import {toAbsoluteUrl} from '../../../_metronic/_helpers'
import { Modal, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {getStores} from 'api/private/store'
import {updateApplicantStore} from 'api/private/applicants'
import {getApplicantStoreDeploy} from 'api/private/store'


const PendingApplicantListItem = ({image, applicantName, dateCreated, company, id, action, update, status, type, deployment}) => {
  const [updateShow, setUpdateShow] = useState(false)
  const [store, setStore] = useState('')
  const [storeId, setStoreId] = useState('')
  const [storeDeployment, setStoreDeployment] = useState('')
  const [stores, setStores] = useState([])
  const handleUpdateShow = async () => {
    listStores()
    const storeDeployment = await getApplicantStoreDeploy(id, company)
    setStoreDeployment(storeDeployment[0].store_name)
    setUpdateShow(true)
  }
  const handleUpdateClose = () => setUpdateShow(false)

  const listStores = async () => {
    const r = await getStores()
    if (r && r.length > 0) {
      setStores(r)
    }
  }
/*s*/
  const capitalizeName = str => {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
  }

  function handleStoreChange(e) {
    e.preventDefault()
    setStoreId(e.target.value)    
    setStore(e.target.value)    
  }

  async function handleStoreUpdate () {
    if (store == "" || store == null || store == undefined) {
      alert('Please choose new store deployment')
    }
    const result = await updateApplicantStore(id, company, storeId)
    if (result == "success") {
      alert("Store Deployment Updated")
    } else {
      alert(result.message)
    }   
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Store Deployment -> {applicantName} -> {storeDeployment}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            id="occupational-test"
            name="assess_deployment_store"
            className={`form-control`}
            onChange={e => handleStoreChange(e)}
            value={store}
            required
          >
            <option value="">Stores</option>
            {stores.map((v, k) => {
                return (
                  <option key={k} value={v.id}>
                    {capitalizeName(v.name)}
                  </option>
                )
              })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleStoreUpdate}>
            Update
          </Button>
          <Button onClick={handleUpdateClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  if (type == "training" && status == 1) {
    return ''
  }
  else {
    return (
      <div className="table-card">
        <div className="table-card_name">
          <div className="symbol symbol-50 symbol-light mr-4">
            <span className="symbol-label">
              <span className="svg-icon align-self-end">
                <img src={toAbsoluteUrl(`${image}`)} alt={applicantName} />
              </span>
            </span>
          </div>
          <div className="table__name d-block">
            <p className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{applicantName}</p>
            <p className="text-muted font-weight-bold">{dateCreated}</p>
            {/*<p className="text-muted font-weight-bold">Test {deployment}</p>*/}
            {status == 1 
              ?
                <p className="badge badge-primary w-50 h-100">Returned From Training</p> 
              :
               ''
            }
          </div>
          <div className="table__name d-block">
          <button
            className="btn btn-light-success font-weight-bolder font-size-sm m-1 w-100"
            onClick={() => action(company, id)}
          >
            View
          </button>
          <button
            className="btn btn-light-success font-weight-bolder font-size-sm m-1 w-100"
            onClick={handleUpdateShow}
          >
            Update Store
          </button>
          </div>
          <MyVerticallyCenteredModal
            show={updateShow}
            onHide={() => setUpdateShow(false)}
          />
        </div>
      </div>

    )    
  }

}

PendingApplicantListItem.defaultProps = {
  image:
    'https://images.pexels.com/photos/4373060/pexels-photo-4373060.jpeg?cs=srgb&dl=pexels-susn-dybvik-4373060.jpg&fm=jpg',
  applicantName: 'Test User',
  dateCreated: 'February 25 2021 03:29 PM',
  company: 'APEX',
  id: 1,
}

PendingApplicantListItem.propTypes = {
  image: PropTypes.string,
  applicantName: PropTypes.string,
  dateCreated: PropTypes.string,
  company: PropTypes.string,
  id: PropTypes.string,
}

export default PendingApplicantListItem

import {post, patch} from 'api/utils/requests'
import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})
export const signIn = async (email, password) => {
  return await post({email, password}, 'internal/auth/login')
}
export const googleSignIn = async (email) => {
  return await post({email}, 'internal/auth/googlelogin')
}

export const updateFB = async (email, id) => {
  return await post({email, id}, 'internal/account/facebook')
}

export const facebookSignIn = async (email, id) => {
  return await post({email, id}, 'internal/auth/facebook_login')
}

export const forgotPassword = async email => {
  return await post({email}, 'internal/auth/forgot')
}

export const updateMobile = async (email, mobile) => {
  return await post({email, mobile}, 'internal/account/mobile')
}

export const sendOtp = async (mobile) => {
  return await post({mobile}, 'internal/account/otp_send')
}

export const validateOtp = async (mobile, otp, time) => {
  return await post({mobile, otp, time}, 'internal/account/otp_validate')
}

export const resetPassword = async (email, password, hash) => {
  return await patch({email, password, hash}, 'internal/auth/reset')
}

export const getUserByToken = async () => {
  const id = ls.get('uid');
  return await post({id}, 'internal/auth/validate');
}

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false
  }
  if (ls.get('token')) {
    return true // prior: true || this JSON is here because we need to fetch the data to populate userDashboard
  } else {
    return false
  }
}

import PropTypes from 'prop-types'
import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/_helpers'

const RecruitmentHeadReviewListItem = ({image, applicantName, dateCreated, company, id, action}) => {
  return (
    <div className="table-card">
      <div className="table-card_name">
        <div className="symbol symbol-50 symbol-light mr-4">
          <span className="symbol-label">
            <span className="svg-icon align-self-end">
              <img src={toAbsoluteUrl(`${image}`)} alt={applicantName} />
            </span>
          </span>
        </div>
        <div className="table__name d-block">
          <p className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{applicantName}</p>
          <p className="text-muted font-weight-bold">{dateCreated}</p>
        </div>
      </div>

      <div className="px-0 text-right table-action">
        <button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => action(company, id)}>
          View
        </button>
      </div>
    </div>
  )
}

RecruitmentHeadReviewListItem.defaultProps = {
  image:
    'https://images.pexels.com/photos/4373060/pexels-photo-4373060.jpeg?cs=srgb&dl=pexels-susn-dybvik-4373060.jpg&fm=jpg',
  applicantName: 'Test User',
  dateCreated: 'February 25 2021 03:29 PM',
  company: 'APEX',
  id: 1,
}

RecruitmentHeadReviewListItem.propTypes = {
  image: PropTypes.string,
  applicantName: PropTypes.string,
  dateCreated: PropTypes.string,
  company: PropTypes.string,
  id: PropTypes.string,
}

export default RecruitmentHeadReviewListItem

import React, {useMemo, useState, useEffect, useRef} from 'react'
import objectPath from 'object-path'
import {useHtmlClassService} from '../../_core/MetronicLayout'
import {SearchDropdown} from '../extras/dropdowns/search/SearchDropdown'
import {QuickUserToggler} from '../extras/QuiclUserToggler'
import {makeStyles} from '@material-ui/core/styles'
import {Modal, Button} from 'react-bootstrap'
import {OutlinedInput, InputLabel, MenuItem, FormControl, Select} from '@material-ui/core'
import SecureLS from 'secure-ls'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

//declare instance of securels
const ls = new SecureLS({encodingType: 'aes'})

export function Topbar() {
  let currentSelectedCompany = ls.get('company')
  let user_details = ls.get('ud')

  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, 'extras.search.display'),
      viewNotificationsDisplay: objectPath.get(uiService.config, 'extras.notifications.display'),
      viewQuickActionsDisplay: objectPath.get(uiService.config, 'extras.quick-actions.display'),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(uiService.config, 'extras.quick-panel.display'),
      viewLanguagesDisplay: objectPath.get(uiService.config, 'extras.languages.display'),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    }
  }, [uiService])

  const [labelWidth, setLabelWidth] = useState(0)
  const [isSwitchable, setSwitchable] = useState([])
  const [company, setCompany] = useState(currentSelectedCompany)
  const [tempCompany, setTempCompany] = useState(currentSelectedCompany)
  const [show, setShow] = useState(false)
  const inputLabel = useRef(null)

  const handleCompany = e => {
    // setLabelWidth(inputLabel.current.offsetWidth)
    const parseData = c => {
      console.log(c)
      if (c.length > 1) {
        return JSON.parse(c).company
      }
    }

    const _company = JSON.parse(user_details).switchable === 1 ? parseData(JSON.parse(e).company) : false
    setSwitchable(_company)
  }

  useEffect(() => {
    handleCompany(user_details)
  }, [])

  const handlePopupConfirmationClose = () => setShow(false)
  const handlePopupConfirmationShow = () => setShow(true)

  const handleChangePopupConfirmation = event => {
    handlePopupConfirmationShow()
    setTempCompany(event.target.value)
  }

  function onConfirmCompanySwitch() {
    //Set selected value to a temporary state holder
    setCompany(tempCompany)

    //overwrite existing value of securels
    ls.set('company', tempCompany)

    //close the confirmation dialog
    handlePopupConfirmationClose()

    //reload the whole page
    window.location.reload(false)
  }

  const classes = useStyles()

  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {isSwitchable.length > 1 ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
            Company
          </InputLabel>
          <Select
            value={company}
            onChange={handleChangePopupConfirmation}
            input={<OutlinedInput labelWidth={labelWidth} id="outlined-age-simple" />}
          >
            {isSwitchable.map((v, k) => {
              return (
                <MenuItem key={`company_${k}`} value={v}>
                  {v}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        ''
      )}

      <Modal show={show} onHide={handlePopupConfirmationClose} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>You will be redirected to another company</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you wish to continue?.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onConfirmCompanySwitch}>
            Switch Company
          </Button>
          <Button variant="primary" onClick={handlePopupConfirmationClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  )
}

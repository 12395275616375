import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {LayoutSplashScreen} from '../../../../_metronic/layout'
import * as auth from '../_redux/authRedux'
import SecureLS from 'secure-ls'

class Logout extends Component {
  componentDidMount() {
    const ls = new SecureLS({encodingType: 'aes'})
    this.props.logout()
    ls.removeAll()
  }

  render() {
    const {hasAuthToken} = this.props
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />
  }
}

export default connect(({auth}) => ({hasAuthToken: Boolean(auth.authToken)}), auth.actions)(Logout)

import {API, SECRET} from './config'

export const formData = _data => {
  let new_formData = new FormData()
  for (var key in _data) {
    new_formData.append(key, _data[key])
  }
  return new_formData
}
const post = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: 'POST',
    headers: {
      'X-API-KEY': `${SECRET}`,
    },
    body: formData(_data),
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const get = async _url => {
  return await fetch(`${API}/${_url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const getStarjobs = async (company) =>{
  return await fetch(`https://api.starjobs.com.ph/webservice-hr-api/internal/applicants/failed/${company}`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const updateStarbjobs = async (id, status) =>{
  return await fetch(`https://api.starjobs.com.ph/webservice-hr-api/internal/applicants/update/status/${id}/${status}`,{
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    }
  })
  .then(response => {
    return response.json()
  })
  .catch(err => {
    console.log(err)
  })
}

const patch = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
    body: JSON.stringify(_data),
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

const remove = async (_data, _url) => {
  return await fetch(`${API}/${_url}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'X-API-KEY': `${SECRET}`,
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.log(err)
    })
}

export {post, get, patch, remove, getStarjobs, updateStarbjobs}

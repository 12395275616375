import {getJobs} from 'api/private/jobs'

let listJobs = new Promise(async (resolve, reject) => {
  const jobs = await getJobs()

  let data = []
  if (jobs) {
    for (let index = 0; index < jobs.length; index++) {
      let infos = JSON.parse(jobs[index].meta_value)

      let exams = jobs[index].exams
      for (let i = 0; i < exams.length; i++) {
        let examInfo = JSON.parse(exams[i].meta_value)
        return {
          id: examInfo.id,
          job_id: examInfo.job_id,
          title: examInfo.title,
          notice: examInfo.notice,
          link: examInfo.link,
        }
      }

      data.push({
        id: jobs[index].id,
        _id: jobs[index].id,
        exams: exams,
        ...infos,
        date_posted: jobs[index].date_created,
      })
    }
  }

  resolve(data)
})

export default listJobs

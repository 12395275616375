import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap-table-next';
import PaginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const {SearchBar} = Search 

const ApplicantLogsTable = ({applicants = [], columns = []}) => {
    let defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }]

    return <ToolkitProvider keyField = 'id'
        data = {applicants}
        columns = {columns}
        search 
        defaultSorted = {defaultSorted}
        pagination = {PaginationFactory()}
    >
        {
            props => 
                <div> 
                    <SearchBar {...props.searchProps} />
                    <hr/>
                    <Table
                        defaultSorted = {defaultSorted}
                        pagination = {PaginationFactory()}
                        {
                            ...props.baseProps
                        }
                    />
                </div>
        
        }
    </ToolkitProvider>
}


ApplicantLogsTable.propTypes = {
    applicants: PropTypes.array,
    columns: PropTypes.array
}

export default ApplicantLogsTable;

